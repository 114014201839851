import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { getHeaders } from './utils';
const API_ENDPOINT = process.env.REACT_APP_RESULT_API_ENDPOINT;
const defaultError = { message: 'Something went wrong, please try again later' };

export const fetchSettings = createAsyncThunk('settings/fetchSettings',
  async ({ team_id, skip, limit, resource }, { rejectWithValue }) => {
    const headers = getHeaders(team_id);
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/teams/resource?resource=${resource}&skip=${skip}&limit=${limit}`,
        headers
      );
      return response.data;  
    } catch (error) {
      return rejectWithValue(error.response.data || defaultError);
    }
  });

export const updatePassword = createAsyncThunk('settings/updatePassword',
  async (payload, { rejectWithValue }) => {
    const headers = getHeaders(payload.team_id);

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/users/update-password`,
        payload,
        headers
      );
      return response.data;        
    } catch (error) {
      return rejectWithValue(error.response.data || defaultError);
    }
  }
);

export const getPlans = createAsyncThunk('settings/getPlans',
  async ({ team_id }, { rejectWithValue }) => {
    const headers = getHeaders(team_id);
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/teams/plans`,
        headers
      );
      return response.data;        
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateProfile = createAsyncThunk('settings/updateProfile',
  async (payload, { rejectWithValue }) => {
    const headers = getHeaders(payload.team_id);
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/users/update-profile`,
        payload,
        headers
      );
      return response.data        
    } catch (error) {
      return rejectWithValue(error.response.data || defaultError);
    }
  }
);

export const verifyPayment = createAsyncThunk('settings/updateProfile',
  async (payload, { rejectWithValue }) => {
    const headers = getHeaders(payload.team_id);
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/payment/verify`,
        payload,
        headers
      );
      return response.data        
    } catch (error) {
      return rejectWithValue(error.response.data || defaultError);
    }
  }
);
