import React, { useEffect, useState } from 'react';
import { Container, Box } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import Grid from '@mui/material/Unstable_Grid2';
import { getUsers, inviteUser, removeUser } from '../../store/actions/userActions';
import { updateUserValue } from '../../store/reducers/userReducers';
import UserProfileCard from '../../components/UserProfileCard';
// import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import PageHeader from '../../components/PageHeader';
import InviteUserDialog from '../../components/dialogs/InviteUserDialog';
import axios from 'axios';
import { getHeaders } from '../../store/actions/utils';
const API_ENDPOINT = process.env.REACT_APP_RESULT_API_ENDPOINT;

const UsersDashbaord = (props) => {
  let team_id
  const state = useSelector((state) => state.users);
  const team = useSelector((state) => state.users.team);

  const dispatch = useDispatch();
  const [ inviteDialog, toggleInviteDialog ] = useState(false);

  const removeUserHandle = (userToRemove) => {
    console.log('userToRemove::: \n', userToRemove);
    const payload = { team_id: team.team_id, ...userToRemove };
    dispatch(removeUser(payload));
  }
  
  const processInvite = payload => {
    dispatch(inviteUser(payload))
  }

  const closeModal = () => {
    dispatch(updateUserValue({ feature: 'invite', key: 'message', value: '' }));
    toggleInviteDialog(!inviteDialog);
  }

  const getTeamId = () => {
    let currentUser = localStorage.getItem('user');
    if (!currentUser) {
      // redirect to Login
    }
    currentUser = JSON.parse(currentUser);
    if (currentUser.team_id) return currentUser.team_id;

    if (!state.teams.length) // redirect to Create team

    currentUser.team_id = state.teams[0].team_id;
    localStorage.setItem('user', JSON.stringify(currentUser));
    return currentUser.team_id;
  }
  
  useEffect(() => {
    dispatch(getUsers({team_id: team.team_id}))
  }, []);

  return (
    <Box component="main" className='page_container' sx={{ flexGrow: 1, p: 3, mt: 6 }}>

        <PageHeader
          title={'Users'}
          subTitle={`Total users: ${state.users.length}`}
          open={inviteDialog}
          // handleClose={toggleInviteDialog}
        />
        <InviteUserDialog
          open={inviteDialog}
          handleClose={closeModal}
          team_id={state.team?.team_id}
          invite={state.invite}
          inviteUser={processInvite}
        />

        <Grid container spacing={3} sx={{ mt: 3}}>
        { state.users.map((user, index) => (
            <Grid xm={4} key={index}>
                <UserProfileCard
                user={user}
                removeUser={removeUserHandle}
                />
            </Grid>
          )) }
        </Grid>

      {/* </Container> */}
      </Box>
  )
}

export default UsersDashbaord