import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
// import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// import { red } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Headset } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PromptDialog from './dialogs/PromptDialog';
import TimeAgo from 'timeago-react';
import DummyProfilePicture from '../components/media/images/profile_picture_placeholder.jpg';
import { Box } from '@mui/material';

export default function UserProfileCard({ user, removeUser }) {
  const [expanded, setExpanded] = React.useState(false);
  const [openPrompt, toggleOpenPrompt] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const options = (user) =>{
    const list = [
      { text: '', func: null },
      // { text: `Remove ${user.first_name}`, func: removeUser },
    ];

    if (user.role === 'user' && !user.accept_invite) {
      list.push({ text: `Cancel invite`, func: removeUser });
    }
    if (user.role === 'user' && user.accept_invite) {
      list.push({ text: `Remove ${user.first_name}`, func: removeUser });
    }

    return list;
  };

  const getTimeAgo = user => {
    if (!user) return

    if (user.role === 'owner') {
      return new Date(user.register_date);
    }

    return new Date(user.invite_date);
  }
  
  const ITEM_HEIGHT = 48;
  
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const getTitle = user => {
      if (user.first_name && user.last_name && user.accept_invite) {
        return {
          title: `About to remove ${user.first_name} ${user.last_name}`,
          body: `Are you sure you want to remove ${user.first_name} ${user.last_name}?`
        };
      }

      if (!user.accept_invite) {
        return { 
          title: `Cancelling invite`,
          body: `Are you sure you want to cancel invite to ${user.user_team_email}?`
        };
      }

      return {
        title: `About to remove ${user.email}`,
        body: `Are you sure you want to remove ${user.email}?`
      };
    }

  return (
    <Card sx={{ width: 360, backgroundColor: '#ededed' }}>
      <PromptDialog
        open={openPrompt}
        handleClose={()=>toggleOpenPrompt(false)}
        // title={`About to remove ${user.first_name} ${user.last_name}..`}
        // body={`Are you sure you want to remove ${user.first_name} ${user.last_name}?`}
        title={getTitle(user).title}
        body={getTitle(user).body}
        handleClick={()=>removeUser(user)}
      />
      <CardMedia
        component="img"
        height="160"
        image={user.profile_photo_url || DummyProfilePicture}
        alt={`${user.first_name || 'image'} ${user.last_name}`}
      />
      <CardContent>
        <Box sx={{fontWeight: 600}}>{user.first_name || 'Nil'} {user.last_name}</Box>
        <Typography variant="body2" color="text.secondary">
          {user.email || user.user_team_email}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <span>{user.role} { !user.is_owner && !user.accept_invite && ' (Pending invite)'}</span>
        </Typography>

        {/* <Typography variant="body2" color="text.secondary">
         <span>{ !user.is_owner && !user.accept_invite && `Invite date: ${new Date(user.invite_date)}`}</span>
        </Typography> */}
        <TimeAgo datetime={getTimeAgo(user)} />

      </CardContent>
      { user.role &&

      <CardActions disableSpacing>
        <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        disabled={user.role === 'owner'}
        onClick={handleClick}
      >
        { user.role === 'owner' && <Headset /> }
        { user.role !== 'owner' && <MoreVertIcon /> }
        {/* <MoreVertIcon />
        <Headset /> */}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {options(user).map((option, index) => (
          <MenuItem key={index} selected={index === 1} onClick={()=>toggleOpenPrompt(true)}>
            {option.text}
          </MenuItem>
        ))}
      </Menu>
      </CardActions>
      }
    </Card>
  );
}