import { createSlice } from '@reduxjs/toolkit';
import { fetchResource, connectPlatform } from '../actions/resourceActions';

const defaultResource = {
  loading: true,
  skip: 0,
  limit: 20,
  total: 0,
  records: [],
  hasMore: false,
  status: 'loading',
  message: '',
};

const teamsUrl = 'https://www.microsoft.com/en-us/microsoft-teams/group-chat-software';
const slackUrl = 'https://slack.com/oauth/v2/authorize?client_id=5576615948067.5576899212882&scope=app_mentions:read,im:history,links:read,chat:write,files:read,files:write,im:read,mpim:history,channels:join,channels:history,groups:history,chat:write.customize&user_scope=';
const discordUrl = 'https://discord.com/oauth2/authorize?client_id=1121772755659014298';

const defaultPlatforms = {
  slack: { name: 'slack', imageUrl: '', url: 'https://slack.com', installationLink: slackUrl },
  discord: { name: 'discord', imageUrl: '', url: 'https://discord.com', installationLink: discordUrl },
  // microsoft: { name: 'microsoft', app_name: 'Microsoft Teams', url: teamsUrl },
}


export const resourceSlice = createSlice({
  name: 'resources',
  initialState: {    
    resources: [],
    team_id: null,
    payments: {...defaultResource },
    platforms: {...defaultResource, defaultPlatforms, connectedPlatforms: {} },
    requests: {...defaultResource },
    plans: [],
    skip: 0,
    limit: 20,
    total: 0,
    loading: true,
    lastUpdated: Date.now(),
  },
  reducers: {
    getResources: (state, action) => {
      state.resource = { name: 'Kay' };
    },
    clearMessage: (state) => {
      state.platforms.message = '';
    },
    getResource: (state, action) => {
      state.users.push(action.payload);
    },
    setTeam: (state, team_id) => {
      state.team_id = team_id;
    },
    getPlatforms: (state) => {
      const result = {...state.platforms.defaultPlatforms};
      state.platforms.records.map(record => result[record.platform_name].teamInfo = record);
      return result;
    }
  },
  extraReducers(builder) {
    // omit posts loading reducers
    builder.addCase(fetchResource.fulfilled, (state, { payload }) => {
      // if (Date.now() - state.lastUpdate < 2 && state.resources.length > 0) return;
      const { results, skip, limit, total, resource, plans } = payload;
      if (plans && plans.length) {
        state.plans = plans;
      }

      if (!state[resource]) return;
      state[resource].loading = false;

      if (state[resource].skip === skip) return;

      if (!results.length) {
        state[resource].hasMore = false;
        return;
      }

      state[resource].skip = skip;
      state[resource].limit = limit || 20;
      state[resource].total = total || 0;
      state[resource].records = [ ...state[resource].records, ...results];
      state[resource].status = 'succeeded';
      if (resource === 'platforms') {
        results.map(r => state.platforms.defaultPlatforms[r.platform_name].teamInfo = r);
      }
    });
    builder.addCase(connectPlatform.pending, (state, { payload })=>{
      state.platforms.loading = true;
      state.platforms.message  ='';
    })
    builder.addCase(connectPlatform.rejected, (state, { payload })=>{
      state.platforms.loading = false;
      state.platforms.message = payload.message;
    })
    builder.addCase(connectPlatform.fulfilled, (state, { payload })=>{
      state.platforms.loading = false;
      const { platform } = payload;
      state.platforms.records = platform;
      state.platforms.message = `Connection to ${platform.platform_name} successful!`;
      window.location.reload();
    })
  }
});

export const { getResources, getResource, setTeam, getPlatforms, clearMessage } = resourceSlice.actions

export default resourceSlice.reducer;
