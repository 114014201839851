import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
// import InviteUserDialog from '../../components/dialogs/InviteUserDialog';
import CreateTeamDialog from '../../components/dialogs/CreateTeamDialog';
import { useSelector } from "react-redux";


const Dashboard = () => {

  const navigate = useNavigate();
  const state = useSelector((state) => state.users);
  const [showForm, setShowForm] = useState(false);

  const manageAccess = () => {
    const user = localStorage.getItem('user');
    if (!user) return navigate('/');
    if (!state.loading && state.user && state.teams.length) {
      navigate('/dashboard/platforms');
    }
    if (!state.loading && state.user && !state.teams.length) {
      setShowForm(true);
    }

  }

  useEffect(()=> {
    manageAccess()
    // navigate('/dashboard/platforms');
  }, []);

  const handleClose = () => {

  }

  return (
    <div>
      {showForm && <CreateTeamDialog open={true} />}
    </div>
  )
}

export default Dashboard