
import { Box, Container, Typography, Paper, Button  } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
// import styles from './page_styles.css';
// import classnames from 'classnames';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const PageHeader = (props) => {
  const { title, subTitle, open, handleClose } = props;
  // , button, buttionFunction

  return (
    <Box
        component="main"
        sx={{ mt: 2, width: '100%', backgroundColor: '#ededed', px: 2}}
        >
      {/* <Paper elevation={0} sx={{backgroundColor: 'turquoise'}}> */}
      <div className="page_header88">
        <Grid container spacing={2}>
          <Grid xs={7}>
            <Typography sx={{ fontSize: 16, fontWeight: 900 }} variant='h6'>
              {title}
            </Typography>

            <Typography>
            {subTitle}
            </Typography>
          </Grid>

        <Grid xs={4}>
        </Grid>
        <Grid xs={1}>
          {
            handleClose && (
              <Button>
                <AddOutlinedIcon onClick={()=>handleClose(!open)} fontSize="large" style={{ float: 'right' }} />
            </Button>  
            )
          }
        </Grid>
      </Grid>
      </div>
      {/* </Paper> */}
    </Box>
  )
}

export default PageHeader;