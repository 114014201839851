import React, { useState } from 'react';
import { Container, Card, TextField, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import { updateValue } from '../../store/reducers/settingsReducers';
import { updateProfile } from '../../store/actions/settingsActions';

const ProfileSettings = ({ hasPassword, team_id, user }) => {
  const state = useSelector((state) => state.settings.profile);
  const dispatch = useDispatch();

  const [userData, setUserData] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
  });

  const handleChange = e => {
    setUserData({ ...userData, [e.target.id]: e.target.value });
  }

  const validateForm = () => {
    const key = 'message';
    const feature = 'profile';
    let value
    dispatch(updateValue({key, value: '', feature }));

    if (!userData.first_name || userData.first_name.length < 2) {
      value = 'First name required';
      return dispatch(updateValue({key, value, feature }));
    }
    if (!userData.last_name || userData.last_name.length < 2) {
      value = 'Last name required';
      return dispatch(updateValue({key, value, feature }));
    }

    const payload = { team_id, ...userData }
    dispatch(updateValue({key: 'loading', value: true, feature: 'profile' }));
    dispatch(updateProfile(payload));
  }

return (
  <Container>
      {/* <PageHeader
        title={hasPassword ? 'Change Password' : 'Secure your account'}
        subTitle={ 'Fill the form to update your password' }
      /> */}
    <Card sx={{ px: 3, pb: 4 }} variant="outlined" >
      <b><h2>Update profile</h2></b>
      <div style={{ color: 'red', fontSize: 14 }}>{state.message}</div>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid xs={4}>
            <Typography sx={{mt: 2}}>
              Email
            </Typography>
          </Grid>
          <Grid xs={8}>
              <TextField
              id="email"
              name="email"
              type='email'
              size="small"
              color="secondary"
              disabled
              value={user.email}
              onChange={handleChange}
            />
          </Grid>

        <Grid xs={4}>
          <Typography sx={{mt: 2}}>
            First name
          </Typography>
        </Grid>
        <Grid xs={8}>
            <TextField
            id="first_name"
            label="First name"
            name="first_name"
            color="secondary"
            type='text'
            size="small"
            value={userData.first_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid xs={4}>
          <Typography sx={{mt: 2}}>
            Last name
          </Typography>
        </Grid>
        <Grid xs={8}>
        <TextField
          id="last_name"
          label="Last name"
          name="last_name"
          type='text'
          color="secondary"
          size="small"
          value={userData.last_name}
          onChange={handleChange}
        />
        </Grid>

        <Grid xs={4}></Grid>
        <Grid xs={8}>
          <Button variant="outlined" color='secondary' sx={{mt: 3}} onClick={validateForm}>
            { state.loading && <CircularProgress color='inherit' size={14} />}
            &nbsp; Update profile
          </Button>
        </Grid>

      </Grid>
      </Card>
  </Container>
)}

export default ProfileSettings;