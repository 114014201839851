import React, { useState, useEffect } from 'react';
import { Container, Card, TextField, Button, Box, Alert } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import evokeLogo2 from '../../components/media/logos/evoke-logo-no-background.svg';

const AcceptInvite = () => {
  const API_ENDPOINT = process.env.REACT_APP_RESULT_API_ENDPOINT;
  const params = useParams();
  const navigate = useNavigate();

  const [payload, setPayload] = useState({
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    password2: '',
    team_name: '',
  });

  // const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const handleChange = e => {
    setPayload({ ...payload, [e.target.id]: e.target.value });
  }

  const checkAccess = async () => {
    let user = localStorage.getItem('user');
    if (!user) return;
    try {
      user = JSON.parse(user);
      navigate('/');
    } catch (error) {
      
    }
  }


  const verifyTokenApi = async() => {
    setError(false);
    const token = encodeURIComponent(params.token)
    try {
      const { data: { email, team_name } } = await axios.get(`${API_ENDPOINT}/auth/signup/verify/${token}`, payload);
      // alert(JSON.stringify(data))
      // setMessage(`Fill the form below to secure your account and join your team <b>${team_name}</b>.`);
      setPayload({ ...payload, email, team_name });
      setLoading(false);
      setShowForm(true);
      setWelcomeMessage(`Fill the form below to secure your account and join your team ${team_name}`)
    } catch (error) {
      let errMsg = 'You are unable to verify at the moment. Please try again later.';
      if (error?.response?.data) {
        errMsg = error.response.data.message;
      }
      setLoading(false);
      setShowForm(false);
      setError(true);
      setWelcomeMessage(errMsg);
    }
  }

  useEffect(()=> {
    checkAccess()
    verifyTokenApi()
  },[]);

  const secureApi = async() => {
    const token = encodeURIComponent(params.token)
    try {
      const { data } = await axios.post(`${API_ENDPOINT}/auth/verify/${token}`, payload);
      if (data.user) {
        localStorage.setItem('user', JSON.stringify(data.user));
        window.location.reload();
      }
      setLoading(false);
    } catch (error) {
      let errMsg = 'You are unable to proceed at the moment. Please try again later.';
      if (error?.response?.data) {
        errMsg = error.response.data.message;
      }
      setLoading(false);
      setError(true);
      setWelcomeMessage(errMsg);
    }
  }

  const validateForm = () => {
    setError(false);
    let value
    if (!payload.first_name || payload.first_name.length < 2) {
      value = 'First name required';
    }
    if (!payload.last_name || payload.last_name.length < 2) {
      value = 'Last name is required';
    }

    if (!payload.password || payload.password.length < 8) {
      value = 'Password should be at least 8 characters';
    }
    if (payload.password !== payload.password2) {
      value = 'Passwords do not match';
    }

    if (value) {
      setError(true);
      setWelcomeMessage(value);
      return;
    }

    setLoading(true)
    secureApi()
  }

return (
  <Box sx={{minHeight: 820}}>
    <Box sx={{ height: 60}}></Box>
    <Grid container spacing={2} sx={{mt: 2}}>
    <Grid xs={6}> </Grid>
    <Grid xs={1}>
      { loading && <CircularProgress /> }
    </Grid>
    <Grid xs={5}> </Grid>

      <Grid xs={4}> </Grid>
      <Grid xs={4}>
      {!loading && <Card sx={{ px: 0, pb: 4 }}>
      <Box >
        <img src={evokeLogo2} height={35} />
      </Box>

      <Box sx={{px: 3, py: 4}}>
      <b><h3>Welcome {payload.email} </h3></b>

        <Box sx={{my: 3, maxWidth: 550}}>
          { welcomeMessage && <Alert severity={error ? 'error' : 'success'}>
          {welcomeMessage}
        </Alert>}
        </Box>

        {showForm && <Grid container spacing={2} sx={{mt: 2}}>
          {/* <Grid xs={1}></Grid>
          <Grid xs={10}>
            <div style={{ color: 'red', fontSize: 15 }}>{error}</div>
          </Grid>

          <Grid xs={1}>
          </Grid> */}
          <Grid xs={10}>
          <div style={{ color: 'red', fontSize: 15 }}>{error}</div>
          <TextField
            id="email"
            label="Email"
            name="email"
            type='email'
            color="secondary"
            size="small"
            disabled
            fullWidth
            value={payload.email}
            onChange={handleChange}
          />
          </Grid>


          <Grid xs={1}>
          </Grid>
          <Grid xs={10}>
          <div style={{ color: 'red', fontSize: 15 }}>{error}</div>
          <TextField
            id="first_name"
            label="First name"
            name="first_name"
            type='text'
            size="small"
            color="secondary"
            fullWidth
            value={payload.first_name}
            onChange={handleChange}
          />
          </Grid>

          <Grid xs={1}>
          </Grid>

          <Grid xs={1}>
          </Grid>
          <Grid xs={10}>
          <TextField
            id="last_name"
            label="Last name"
            name="last_name"
            type='text'
            size="small"
            color="secondary"
            fullWidth
            value={payload.last_name}
            onChange={handleChange}
          />
          </Grid>
          <Grid xs={1}>
          </Grid>

          <Grid xs={1}>
          </Grid>
          <Grid xs={10}>
          <TextField
            id="password"
            label="Password"
            name="last_name"
            type='password'
            placeholder='********'
            size="small"
            fullWidth
            color="secondary"
            value={payload.password}
            onChange={handleChange}
          />

          <TextField
            id="password2"
            label="Re-type password"
            name="password2"
            type='password'
            placeholder='********'
            size="small"
            fullWidth
            sx={{mt: 3}}
            color="secondary"
            value={payload.password2}
            onChange={handleChange}
          />
          </Grid>
          <Grid xs={1}>
          </Grid>

          <Grid xs={4}></Grid>
          <Grid xs={8}>
            <Button variant="outlined" color="secondary" onClick={validateForm}>
              { loading && <CircularProgress color='inherit' size={14} />}
                &nbsp; Secure account
              </Button>
          </Grid>
        </Grid>}

      </Box>


      </Card>}
      </Grid>
      <Grid xs={4}> </Grid>
    </Grid>
    </Box>
)}

export default AcceptInvite;