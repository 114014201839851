import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import ChangePassword from '../../components/settingsComponents/ChangePassword';
import { useSelector, useDispatch } from "react-redux";
import { updateValue } from '../../store/reducers/settingsReducers';
import ProfileSettings from '../../components/settingsComponents/ProfileSettings';
import PaymentSettings from '../../components/settingsComponents/PaymentSettings';

import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


// const AntTab = styled((props) => <Tab disableRipple {...props} />)(
//   ({ theme }) => ({
//     textTransform: 'none',
//     minWidth: 0,
//     [theme.breakpoints.up('sm')]: {
//       minWidth: 0,
//     },
//     fontWeight: theme.typography.fontWeightRegular,
//     marginRight: theme.spacing(1),
//     color: 'rgba(0, 0, 0, 0.85)',
//     fontFamily: [
//       '-apple-system',
//       'BlinkMacSystemFont',
//       '"Segoe UI"',
//       'Roboto',
//       '"Helvetica Neue"',
//       'Arial',
//       'sans-serif',
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//     ].join(','),
//     '&:hover': {
//       color: '#40a9ff',
//       opacity: 1,
//     },
//     '&.Mui-selected': {
//       color: '#1890ff',
//       fontWeight: theme.typography.fontWeightMedium,
//     },
//     '&.Mui-focusVisible': {
//       backgroundColor: '#d1eaff',
//     },
//   }),
// );

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
});

// interface StyledTabProps {
//   label: string;
// }

const StyledTab = styled((props) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  // color: 'rgba(255, 255, 255, 0.7)',
  '&.Mui-selected': {
    // color: '#fff',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));


const Settings = () => {
  // const state = useSelector((state) => state.resources.platforms);
  const userState = useSelector((state) => state.users.user);
  const team = useSelector((state) => state.users.team);
  // const dispatch = useDispatch();
  const appName = process.env.REACT_APP_APP_NAME;

  const [value, setValue] = useState(0)

  const handleChange = (e, p) => {
    setValue(p)
  }

  useEffect(()=> {
  }, []);

  // bgcolor: '#2e1534'

  return (
    <Container className='page_container' sx={{p: 3, mt: 6}}>
      {/* <Box sx={{ bgcolor: '#fff' }}>
        <Box sx={{ p: 3 }} />
      </Box> */}
      <b><h2>Settings</h2></b>
      <Box sx={{ bgcolor: '#F8F6F4', mb: 5, pb: 5 }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label="Profile" id={0} />
          <StyledTab label="Plans" id={1} />
          <StyledTab label="Security" id={2} />
        </StyledTabs>
        <Box sx={{ p: 3 }} />
          { value === 0 && <ProfileSettings team_id={1} user={userState} /> }
          { value === 2 &&
            <ChangePassword
              team_id={userState.team_id}
              hasPassword={userState.hasPassword}
              updatePassword={updateValue}
          />
          }
          { value === 1 && <PaymentSettings user={userState} /> }
        </Box>
    </Container>
  )}

export default Settings;