import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {Card, Box, Icon} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinkPlatformDialog from './dialogs/LinkPlatformDialog';
import { useSelector, useDispatch } from "react-redux";
import { connectPlatform } from '../store/actions/resourceActions';
import { clearMessage } from '../store/reducers/resourceReducers';
import SlackLogo1 from '../components/media/logos/slack_01.jpg';
import DiscordLogo1 from '../components/media/logos/discord1.png';
import MicrosoftTeamsLogo1 from '../components/media/logos/ms_teams1.jpg';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { purple } from '@mui/material/colors';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
// import MicrosoftTeamsLogo2 from '../components/media/logos/ms_teams2.png';
import Button from '@mui/material/Button';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function PlatformCard(props) {
  const { platform, steps, name, team_id } = props;
  const state = useSelector((state) => state.resources.platforms);
  // const team = useSelector((state) => state.users.team);
  const dispatch = useDispatch();

  const { teamInfo } = platform;
  const [expanded, setExpanded] = React.useState(false);
  const [open, handleClose ] = React.useState(false);
  const [code, setCode] = React.useState('');

  useEffect(()=>{
    if (!open) {
      dispatch(clearMessage());
    }
  },[open]);

  const handleChange = (e) => {
    setCode(e.target.value)
  };

  const logos = {
    microsoft: MicrosoftTeamsLogo1,
    slack: SlackLogo1,
    discord: DiscordLogo1,
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClick = code => {
    // console.log(team_id, platform.name, code)
    dispatch(connectPlatform({ team_id, code: code, platform: platform.name }));
    setCode('');
  }

  const installClicked = url => {
    window.location.assign(platform.installationLink);
  }

  const backgroundColor = teamInfo ? '#FFFEEA' : null;

  const slkBase = 'https://slack.com/oauth/v2/authorize';
  const slkClientId = process.env.REACT_APP_SLACK_CLIENT_ID;
  const slkBaseClient = `${slkBase}?client_id=${slkClientId}`;
  const slkScopes = 'scope=app_mentions:read,channels:join,chat:write,files:read,im:history,links:read,team:read&user_scope=identify,users:read,users:read.email';
  const slackInstallLink = `${slkBaseClient}&${slkScopes}`;
  const SlackButton = () => <Box>
    <a href={slackInstallLink}>
      <img alt="Add to Slack"
        height="40"
        width="139"
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
      </a>
    </Box>


  return (
    <Card sx={{ maxWidth: 280, minWidth: 280, minHeight: 250, height: 300, backgroundColor }}>
      {/* maxWidth: 280,  */}
      <LinkPlatformDialog
        open={open}
        handleClose={handleClose}
        platform={platform}
        loading={state.loading}
        message={state.message}
        code={code}
        handleChange={handleChange}
        handleClick={handleClick}
      />
      <CardMedia
        component="img"
        height="100"
        image={logos[name]}
        alt={`${name} logo`}
      />
      <CardContent>
        {!teamInfo && <Box sx={{mb: 5, fontSize: 15}}>
        <LinkOffIcon />
          <Box>Login with {name} to link</Box>
          {/* <Box>
            <Box>Install the bot to your {name} { name === 'discord' ? 'server' : 'workspace' } </Box>
            <Button
              size='small'
              variant='contained'
              color="secondary"
              sx={{mt: 1}}
              onClick={()=>installClicked()}
              >Install {name} Bot</Button>
            </Box> */}

            {/* <Box sx={{mt: 4}}>
              <Box>Past the code provided by the bot from your { name === 'discord' ? 'server' : 'workspace' } here</Box>
              <Button
              variant="outlined"
              size='small'
              color="secondary"
              sx={{mt: 2}}
              startIcon={<AppRegistrationOutlinedIcon />}
              onClick={()=>handleClose(!open)}
              >
                Connect to {name}
              </Button>
            </Box> */}
        </Box>}

        { teamInfo && <Box>
          <Box>
            <LinkIcon />
          <Typography variant='body' sx={{ mb: 1.5, mt: 1, lineHeight: 2, fontWeight: 600 }} color="text.secondary" fontWeight={100}>
            Connected to {name}
            </Typography>
          </Box>
          {teamInfo && teamInfo.team_platform_id && <Box sx={{mt: 4}}>
            <Typography variant='body' sx={{ mb: 1.5, mt: 1, lineHeight: 2 }} color="text.secondary" fontWeight={100}>
              {teamInfo.workspace_name}
            </Typography>
            </Box>}
        </Box> }

        {/* { `${JSON.stringify(teamInfo)}` } */}

      </CardContent>
      <CardActions disableSpacing>
        <Box>
        {name === 'slack' && (!teamInfo || !teamInfo.team_platform_id) && <SlackButton />}
          {name !== 'slack' && <Button
              size='small'
              variant='contained'
              sx={{mt: 1, backgroundColor: purple['400']}}
              onClick={()=>installClicked()}
              >Install {name} Bot</Button>}
        </Box>

        {/* <ExpandMore
          onClick={installClicked}
          aria-label={`Install to ${name} `}
        >
        </ExpandMore> */}
      </CardActions>

      {/* {
        !teamInfo && (<CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      )} */}
      {/* { !teamInfo && (
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
        <h2><b>How to link {name} account</b></h2>
          { name && steps(name).map((step, index) =>(
            <Typography paragraph key={index}><b>{step}</b></Typography>
          )) }
        </CardContent>
      </Collapse>
      )} */}
      
    </Card>
  );
}