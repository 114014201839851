import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';

export const makeNewPayment = createAsyncThunk(
  'payment/makeNewPayment',
  async payload => {
    const response = await Axios.post('/fakeApi/posts', payload)
    return response.data
  }
);

export const fetchPayments = createAsyncThunk(
  'payments/fetchPayments',
  async payload => {
    const response = await Axios.post('/fakeApi/posts', payload)
    return response.data
  }
)

export const paymentSlice = createSlice({
  name: 'payments',
  initialState: {
    payment: {},
    payments: [],
  },
  reducers: {
    getPayments: (state) => {
      state.user = { name: 'Kay' };
    },
    getPayment: (state, action) => {
      state.users.push(action.payload);
    },
  },
  extraReducers(builder) {
    // omit posts loading reducers
    builder.addCase(fetchPayments.fulfilled, (state, action) => {
      // We can directly add the new post object to our posts array
      state.payments.push(action.payload)
    });
    builder.addCase(makeNewPayment.fulfilled, (state, action) => {
      state.users.filter(user => user.id !== action.payload.id);
    });
  }
})


export const { getPayments, getPayment } = paymentSlice.actions

export default paymentSlice.reducer
