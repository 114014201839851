import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { getHeaders, defaultError } from './utils';

const API_ENDPOINT = process.env.REACT_APP_RESULT_API_ENDPOINT;

export const fetchRequests = createAsyncThunk('requests/fetchRequests',
  async ({ team_id, skip, limit }, { rejectWithValue }) => {
  const headers = getHeaders(team_id);
    const response = await axios.get(`${API_ENDPOINT}/teams/requests?skip=${skip}&limit=${limit}`, headers)
    return response.data
  }
)

export const clearResult = createAsyncThunk('requests/clearResult',
  async ({ task_id, team_id }, { rejectWithValue }) => {
  const headers = getHeaders(team_id);
  try {
    const response = await axios.delete(`${API_ENDPOINT}/teams/task/result/${task_id}`, headers)
    return response.data;    
  } catch (error) {
    return rejectWithValue(error.response.data || defaultError);
  }
  }
)

export const fetchPayments = createAsyncThunk('resource/fetchResources',
  async ({ team_id, skip, limit, resource }, { rejectWithValue }) => {
    const headers = getHeaders(team_id);
    const response = await axios.get(`${API_ENDPOINT}/teams/${resource}?skip=${skip}&limit=${limit}`, headers)
    return response.data
  }
);
