import React, { useEffect, useState } from 'react';
import {Box, Button} from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import { fetchRequests, clearResult } from '../../store/actions/requestActions';
import { clearError } from '../../store/reducers/requestsReducers';
import RequestsTable from '../../components/RequestsTable';
import PromptDialog from '../../components/dialogs/PromptDialog';
// import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
// import TopicIcon from '@mui/icons-material/Topic';
// import LinkIcon from '@mui/icons-material/Link';

const RequestsPage = (props) => {
  const state = useSelector((state) => state.requests);
  const team = useSelector((state) => state.users.team);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectRecord, setSelectedRecord] = useState({});
  const [ dialogData, setDialogData ] = useState({ title: '', body: '', body2: '', list: [] });
  const dispatch = useDispatch();

  useEffect(()=> {
    dispatch(fetchRequests({ team_id: team.team_id, skip: state.skip, limit: state.limit }));
  }, []);

  const clearResultHandle = async (e) => {
    dispatch(clearResult({ team_id: team.team_id, task_id: Number(selectRecord.id)}))
  }

  const clearTitle = (record) => `Clear result for record (${record.serialNo})`;
  let clearBody = `
  You are about to clear the result of this analysis.
  \n\nThis action is encouraged but not reversible.
  \n\nYou can always re-analyze the data.`

  const getList = (record) => {
    const r = [
      {key: 'Sn', value: record.serialNo, Icon: 'FormatListNumberedIcon'},
      {key: 'File name', value: record.file_name, Icon: 'TopicIcon'},
      {key: 'Ref', value: record.reference, Icon: 'LinkIcon'},
    ]
    return r
  }

  const handleAction = (action, record, index = 0) => {
    if (action === false) return setOpenDialog(false);
    const newRecord = {...record, serialNo: index + 1 }


    setSelectedRecord(newRecord);
    setDialogData({
      title: clearTitle(newRecord),
      body: clearBody,
      list: getList(newRecord),
      page: 'clearResult'
    });
    setOpenDialog(true);
  }

  const resetData = () => {
    if (openDialog === false) {
      dispatch(clearError());
    }
  }

  useEffect(()=>{
    resetData(openDialog)
  }, [openDialog])


  return (
    <Box
        component="main"
        className='page_container'
        sx={{ flexGrow: 1, p: 3, mt: 6 }}
        >
          <PromptDialog
            title={dialogData.title}
            body={dialogData.body}
            body2={dialogData.body2}
            open={openDialog}
            page='clearResult'
            message={state.message}
            error={state.error}
            loading={state.loading}
            list={dialogData.list}
            handleClose={()=>setOpenDialog(false)}
            handleClick={clearResultHandle}
          />
        <div sx={{ mt: 3 }}>
          <b><h2>Data Processing requests</h2></b>
        </div>
        <div>
          { state.requests.length > 0 && <RequestsTable {...state} handleClose={setOpenDialog} handleAction={handleAction} />}
          { state.requests.length === 0 && (<div>
            <h3>You have no records for data processing for now.</h3>
          </div>)}
            <div>
              { state.page > 1 && <Button variant="text">Prev</Button>}
              
              { state.hasMore && <Button variant="text" onClick={()=>dispatch(fetchRequests({
                team_id: team.team_id,
                skip: state.skip,
                limit: state.limit
                }))}>
                  Next
              </Button>}
              
            </div>
        </div>

    </Box>
  )
}

export default RequestsPage;