

export const getHeaders = (team_id) => {
  let user = localStorage.getItem('user');
  if (!user) return false;
  user = JSON.parse(user);
  const headers = { headers: {
    'Content-type': 'application/json',
    Authorization: `Bearer ${user.token}`,
  } }

  if (team_id) {
    headers.headers.team_id = team_id;
    headers.headers.teamid = team_id;
  }

  return headers;
}

export const defaultError = { message: 'Something went wrong, please try again later' };
export const loggedOutError = { message: "You are logged out!" };
