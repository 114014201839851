import * as React from 'react';
import {Button, Box, CircularProgress} from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { getHeaders } from '../../store/actions/utils';

export default function CreateTeamDialog(props) {
  const API_ENDPOINT = process.env.REACT_APP_RESULT_API_ENDPOINT;

  const { open } = props;
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [company_name, setCompany_name] = React.useState('');

  const roles = [
    { text: '', value: null },
    { text: 'User', value: 'user' },
    { text: 'Owner', value: 'owner' },
  ]

  const createTeamApi = async() => {
    try {
      const { data } = await axios.post(`${API_ENDPOINT}/auth/social/team`, { company_name }, getHeaders());
      window.location.reload();
    } catch (error) {
      let errMsg = 'You are unable to proceed at the moment. Please try again later.';
      if (error.response && error.response.data) {
        errMsg = error.response.data.message;
      }
      setLoading(false);
      setMessage(errMsg);
    }
  }

  const validateForm = () => {
    setMessage('');

    if (!company_name || company_name.length < 4) {
      setMessage('Workspace/company name should be at least 4 characters');
      return
    }
    createTeamApi()
  }

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle sx={{mt: -3}}><b><h2>Create workspace</h2></b></DialogTitle>
        <DialogContent sx={{minWidth: 300, minHeight: 140}}>
        <DialogContentText sx={{fontSize: 16}}>
            Create a workspace for you and your team
          </DialogContentText>

          <Box sx={{p: 1, color: 'red', my: 1}}>
            {message}
          </Box>
          <TextField
            autoFocus
            margin="dense"
            id="company_name"
            label="Company name"
            type="text"
            value={company_name}
            fullWidth
            onChange={(e)=>setCompany_name(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={()=>handleClose(!open)}>Cancel</Button> */}
          {/* <Button onClick={validateForm}>Create Workspace</Button> */}
          <Button variant="contained" onClick={validateForm}>
            { loading && <CircularProgress color='inherit' size={14} />}
              &nbsp; Create Workspace
            </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}