import { createSlice } from '@reduxjs/toolkit';
import { getUsers, getMe, inviteUser, removeUser } from '../actions/userActions';

  const getTeamId = (payload) => {
    let user = localStorage.getItem('user')
    user = JSON.parse(user);
    if (user.team_id) {
      const getTeam = payload.teams.filter(t => t.team_id === user.team_id);
      if (getTeam) {
        return getTeam[0].team_id;
      }
    } else {
      const team_id = payload.teams[0].team_id;
      user.team_id = team_id;
      localStorage.setItem('user', JSON.stringify(user));
      return team_id;
    }
  }

export const userSlice = createSlice({
  name: 'users',
  initialState: {
    user: null,
    users: [],
    invites: [],
    team_id: null,
    teams: [],
    skip: 0,
    limit: 20,
    total: 0,
    status: '',
    loading: false,
    invite: { loading: false, message: '' },
    invite_loading: true,
  },
  reducers: {
    updateUserValue: (state, { payload }) => {
      console.log('---- userResuxer: ', payload)
      const { feature, key, value } = payload;
      if (!feature) return state[key] = value;

      state[feature][key] = value;
    },

    logOut: (state) => {
      state.user = null;
    },
    rvUser: (state) => {
      state.user = null;
    },
    checkUser: (state) => {
      let user = localStorage.getItem('user');
      if (!user) {
        state.loading = false;
        return null
      }
  
      try {
        user = JSON.parse(user);
        if (!user || !user.token) {
          state.loading = false;
          return null
        }
        if (state.loading) return 'loading';

        if (!state.loading && !state.user) return 'login';
        return 'dashboard';
        // await dispatch();
      } catch (error) {
        state.loading = false;
        return 'login'
      }
    },
    selectTeam: (state, { payload }) => {
      let user = localStorage.getItem('user');
      user = JSON.parse(user);
      user.team_id = parseInt(payload);
      localStorage.setItem('user', JSON.stringify(user));
      state.team_id = payload;
    },
    addUser: (state, action) => {
      state.users.push(action.payload);
    },
    defaultTeam: (state, action) => {
      if (!state.teams?.length) return null;

      let user = localStorage.getItem('user');
      if (!user) {
        // logout
        return;
      }
      try {
        user = JSON.parse(user);
        if (user.team_id) {
          const getTeam = state.teams.filter(t => t.team_id === user.team_id);
          if (getTeam) {
            state.team_id = getTeam[0].team_id;
            return;
          }
        } else {
          user.team_id = state.teams[0].team_id;
          localStorage.setItem('user', JSON.stringify(user));
          state.team_id =  state.teams[0].team_id;
          return;
        }
      } catch (error) {
        // logOut
      }
  
    }
  },
  extraReducers(builder) {
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      const { users, skip, limit, total } = payload;
      state.loading = false;
      if (state.skip === skip) return;
  
      state.users = [...state.users, ...users];
      state.skip = skip;
      state.limit = limit;
      state.total = total;
      state.status = 'succeeded';
    });
    builder.addCase(inviteUser.fulfilled, (state, { payload }) => {
      state.invites.push(payload.user);
      state.invite.loading = false;
      state.invite.message = payload.message
    });
    builder.addCase(inviteUser.pending, (state, { payload }) => {
      state.invite.loading = true;
      state.invite.message = '';
    })
    .addCase(inviteUser.rejected, (state, { payload }) => {
      state.invite.loading = false;
      state.invite.message = payload.message;
    })
    .addCase(removeUser.fulfilled, (state, { payload }) => {
      // console.log('==== remoiuvieUserx', payload);
      window.location.reload()
    })
    .addCase(removeUser.pending, (state, action) => {
      state.status = 'loading';
      state.loading = true;
    })
    .addCase(removeUser.rejected, (state, { payload }) => {
      state.invite.loading = false;
      state.invite.message = payload.message;
    })

    // builder.addCase(removeUser.fulfilled, (state, action) => {
    //   state.users.filter(user => user.id !== action.payload.id);
    // })
    .addCase(getUsers.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(getUsers.rejected, (state, action) => {
      state.status = 'failed';
    })
    .addCase(getMe.rejected, (state, { payload: { data } }) => {
      state.status = 'failed';
      state.loading = false;
      if (data && (data.code === 403 || ['signIn', 'invalid_token'].includes(data.action))) {
        localStorage.removeItem('user');
        window.location.reload();
      }
    })
    .addCase(getMe.pending, (state, action) => {
      state.status = 'loading';
      state.loading = true;
    })
    .addCase(getMe.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = payload.user;
      state.teams = payload.teams || [];
      state.team = payload.teams[0];
      state.invites = payload.invites?.length || [];
      state.status = 'succeeded';
      // const team_id = getTeamId(payload);
      // state.team_id = team_id;
    })

  }
})

export const { defaultTeam, selectTeam, rvUser, addUser, updateUserValue, logOut, checkUser } = userSlice.actions

export default userSlice.reducer;
