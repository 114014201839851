import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import PaymentTable from '../../components/PaymentTable';
import { useSelector, useDispatch } from "react-redux";
import { fetchResource } from '../../store/actions/resourceActions';
const resource = 'payments';

const Payments = (props) => {
  const dispatch = useDispatch();

  const { payments, plans } = useSelector((state) => state.resources);
  const team = useSelector((state) => state.users.team);
  const { skip, limit } = payments;

  const getPlansObj = () => {
    const result = {};

    plans.forEach(plan => {
      result[plan.id] = plan
    });
    return result;
  }

  useEffect(()=> {
    dispatch(fetchResource({ resource, team_id: team.team_id, skip, limit }));
  }, []);

  return (
    <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, mt: 6 }}
        >
        <b><h2>Payments</h2></b>

        { payments.records.length > 0 && <PaymentTable {...payments} plansObj={getPlansObj()} />}
        { payments.records.length === 0 && (
        <div>
          <h3>You have no payments records for now.</h3>
        </div>)}
    </Box>
  )
}

export default Payments