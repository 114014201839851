import * as React from 'react';
import { styled } from '@mui/material/styles';
import {Table, Button} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dateAndTime from 'date-and-time';
import discordIcon from './media/icons/discord.svg';
import slackIcon from './media/icons/slack.svg';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(
  name,
  calories,
  fat,
  carbs,
  protein,
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const nf = new Intl.NumberFormat('en-US');

export default function RequestsTable({ requests, handleClose, handleAction }) {

  const getPlatformIcon = (platform) => {
    if (platform === 'slack') return slackIcon;
    if (platform === 'discord') return discordIcon;
  }

  // dateAndTime.format(created_at, 'ddd, MMM DD YYYY hh:mm A')

  const formatFileName = name => name.length <= 10 ? name : name.substr(0, 19) + '...';
  const baseUsr = 'https://evoked.io/result';

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 850 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Sn</StyledTableCell>
            {/* <StyledTableCell align="left">Platform&nbsp;</StyledTableCell> */}
            {/* <StyledTableCell align="left">User &nbsp;</StyledTableCell> */}
            <StyledTableCell align="left">File name</StyledTableCell>
            <StyledTableCell align="left">Size&nbsp;</StyledTableCell>
            <StyledTableCell align="left">Rows&nbsp;</StyledTableCell>
            <StyledTableCell align="left">Col&nbsp;</StyledTableCell>
            <StyledTableCell align="left">Date&nbsp;</StyledTableCell>
            <StyledTableCell align="left">Reference&nbsp;</StyledTableCell>
            <StyledTableCell align="left">Clear result</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {requests.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {index + 1} &nbsp; &nbsp;
                <img src={getPlatformIcon(row.platform)} alt={row.platform} height={10} />
              </StyledTableCell>
              {/* <StyledTableCell align="left">
                <img src={getPlatformIcon(row.platform)} alt={row.platform} height={10} />
                </StyledTableCell> */}
              {/* <StyledTableCell align="left">{row.user}</StyledTableCell> */}
              <StyledTableCell align="left">{formatFileName(row.file_name)}</StyledTableCell>
              <StyledTableCell align="left">{row.file_size}</StyledTableCell>
              <StyledTableCell align="left">{nf.format(row.row_size)}</StyledTableCell>
              <StyledTableCell align="left">{nf.format(row.column_size)}</StyledTableCell>
              <StyledTableCell align="left">
                {dateAndTime.format(new Date(row.date), 'ddd, MMM DD YYYY hh:mm A') }
              </StyledTableCell>
              <StyledTableCell align="left">
                <a href={`${baseUsr}/${row.reference}`} target='_blank' rel="noreferrer">
                  {row.reference.substr(0, 14)}
                </a>
              </StyledTableCell>
              <StyledTableCell align="left">
                { row.result_cleared_at ? <Button disabled>...</Button> : 
                <Button onClick={()=>handleAction(true, row, index)}><DeleteOutlineIcon /></Button> }                
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}