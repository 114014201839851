import { createSlice } from '@reduxjs/toolkit';
import { fetchSettings, updatePassword, getPlans, updateProfile } from '../actions/settingsActions';

const loading = false;
const message = '';

export const settingSlice = createSlice({
  name: 'settings',
  initialState: {    
    settings: [],
    team_id: null,
    user: {},
    plans: [],
    skip: 0,
    limit: 20,
    total: 0,
    loading: true,
    password: { loading, message },
    payment: { loading, message, plans: [] },
    profile: { loading, message },
    lastUpdated: Date.now(),
  },
  reducers: {
    updateValue: (state, { payload }) => {
      const { feature, key, value } = payload;
      if (!feature) return state[key] = value;

      state[feature][key] = value;
      // console.log('updateValue_____xxxeee: ', payload);
    },
    getSettings: (state) => {
      state.setting = { name: 'Kay' };
    },
    getSetting: (state, action) => {
      state.users.push(action.payload);
    },
    setTeam: (state, team_id) => {
      state.team_id = team_id;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchSettings.fulfilled, (state, { payload }) => {
      state.status = 'succeeded';
      state.loading = false;
      state.user = payload.user;
    });

    builder.addCase(getPlans.fulfilled, (state, { payload }) => {
      state.payment.status = 'succeeded';
      state.payment.loading = false;
      state.payment.plans = payload.results;
    });

    builder.addCase(updatePassword.fulfilled, (state, { payload }) => {
      state.status = 'succeeded';
      state.password.hasPassword = true;
      state.password.loading = false;
      state.password.message = 'Password updated successfully';
    }).addCase(updatePassword.rejected, (state, { payload }) => {
      state.password.status = 'failed';
      state.password.loading = false;
      state.password.message = payload.message;
    });

    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      state.profile.status = 'succeeded';
      state.profile.loading = false;
      state.profile.message = payload.message;
    }).addCase(updateProfile.rejected, (state, { payload }) => {
      state.profile.status = 'failed';
      state.profile.loading = false;
      state.profile.message = payload.message;
    });

  }
});

export const { getsettings, getsetting, setTeam, updateValue } = settingSlice.actions

export default settingSlice.reducer;
