import { configureStore } from '@reduxjs/toolkit';
import userReducers from "./reducers/userReducers";
import paymentReducers from "./reducers/paymentReducers";
import requestsReducers from './reducers/requestsReducers';
import resourceReducers from './reducers/resourceReducers';
import settingsReducers from './reducers/settingsReducers';

export default configureStore({
  reducer: {
    users: userReducers,
    payments: paymentReducers,
    requests: requestsReducers,
    resources: resourceReducers,
    settings: settingsReducers,
  },
});
