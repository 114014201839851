import * as React from 'react';
import {Button, Box, Typography, List, ListItem, ListItemIcon, ListItemText, Alert} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import FolderIcon from '@mui/icons-material/Folder';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TopicIcon from '@mui/icons-material/Topic';
import LinkIcon from '@mui/icons-material/Link';

const getIcon = icon => {
  if (icon === 'FormatListNumberedIcon') return <FormatListNumberedIcon color="secondary"  />
  if (icon === 'TopicIcon') return <TopicIcon color="secondary"  />
  if (icon === 'LinkIcon') return <LinkIcon color="secondary"  />
}

export default function PromptDialog(props) {
  const { open, title, body, page = '', message = '', error = false, loading = false, list, handleClose, handleClick } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* <b>{ title }</b> */}
          <Typography sx={{ mb: 2, fontWeight: 700 }} variant="h5" component="div">
          { title }
          </Typography>  
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography sx={{ mb: 2, fontSize: 18 }} variant="body">
              { body }
          </Typography>  
          </DialogContentText>

          <Box>
          { page === 'clearResult' && list && list.length &&
              <List dense={false} sx={{my: 2}}>
              {list.map((l, i)=> (
                <ListItem key={i}>
                <ListItemIcon>
                  {getIcon(l.Icon)}
                  <b>{l.key}:&nbsp;</b>
                </ListItemIcon>
                <ListItemText
                  primary={l.value}
                //  secondary={secondary ? 'Secondary text' : null}
                />
              </ListItem>
              ))}
            </List>
          }
          </Box>

        </DialogContent>
        <DialogActions>
        <Box>
        { message && <Alert severity={error ? 'error' : 'success'}>
            {message}
          </Alert>}
        </Box>

          <Button onClick={handleClose} color="secondary">Cancel</Button>
          {loading && <CircularProgress size={20}  color="secondary" />}
          <Button onClick={handleClick} autoFocus variant="contained" color="secondary" >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}