import React, { useState, useEffect } from 'react';
import { Container, Card, TextField, Button, Box, Typography, Alert } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import { red, purple } from '@mui/material/colors';
import logo from '../../components/media/logos/logo192.png';

const CreateTeam = () => {
  const API_ENDPOINT = process.env.REACT_APP_RESULT_API_ENDPOINT;
  const params = useParams();
  const navigate = useNavigate();
  const [payload, setPayload] = useState({
    company_name: '',
    password: '',
    password2: '',
  });

  const [error, setError] = useState(false);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [ message, setMessage ] = useState(''); 
  const handleChange = e => {
    setPayload({ ...payload, [e.target.id]: e.target.value });
  }

  const checkAccess = async () => {
    let user = localStorage.getItem('user');
    if (!user) return 
    // navigate('/');
    try {
      user = JSON.parse(user);
      const headers = { headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      } } 
      verifyUser(headers);   
    } catch (error) {
      
    }
  }

  const verifyTokenApi = async() => {
    setError(false);
    const token = encodeURIComponent(params.token)
    // alert(token);
    try {
      const { data } = await axios.get(`${API_ENDPOINT}/auth/signup/verify/${token}`, payload);
      setMessage(`Your email address ${data.email} has been verified. Create workspace to continue.`);
      // setError(`Your email address ${data.email} has been verified. Create workspace to continue.`)
      setLoading(false);
      setShowForm(true);
    } catch (error) {
      let errMsg = 'You are unable to verify at the moment. Please try again later.';
      if (error?.response?.data) {
        errMsg = error.response.data.message;
      }
      setLoading(false);
      setShowForm(false);
      setError(true);
      setMessage(errMsg);
    }
  }

  const verifyUser = async(headers) => {
    try {
      const { data } = await axios.get(`${API_ENDPOINT}/auth/me`, headers);
      setTeams(data.teams);
      setLoading(false);
      if (!data.teams.length) {
        setShowForm(true);
      }
      setShowForm(true);
    } catch (error) {
      let errMsg = 'You are unable to verify at the moment. Please try again later.';
      if (error?.response?.data) {
        errMsg = error.response.data.message;
      }
      setLoading(false);
      setShowForm(false);
      setError(errMsg);
    }
  }

  useEffect(()=> {
    checkAccess()
    verifyTokenApi()
  }, []);

  const secureApi = async() => {
    setError(false);
    const token = encodeURIComponent(params.token)
    try {
      const { data } = await axios.post(`${API_ENDPOINT}/auth/signup/create-company/${token}`, payload);
      if (data.user) {
        localStorage.setItem('user', JSON.stringify(data.user));
        window.location.reload();
      }
      setLoading(false);
    } catch (error) {
      let errMsg = 'You are unable to proceed at the moment. Please try again later.';
      if (error?.response?.data) {
        errMsg = error.response.data.message;
      }
      setLoading(false);
      setError(errMsg);
      setError(true);
    }
  }

  const validateForm = () => {
    setError(false);
    setMessage('');
    let value
    if (!payload.company_name || payload.company_name.length < 4) {
      value = 'Company/workspace name is required and should be at least 4 characters';
    }
    if (!payload.password || payload.password.length < 8) {
      value = 'Password should be at least 8 characters';
    }
    if (payload.password !== payload.password2) {
      value = 'Passwords do not match';
    }

    if (value) {
      setError(true);
      setMessage(value);
      return
    }

    setLoading(true);
    secureApi();
  }

return (
  <Container>
    <Grid container spacing={2} sx={{mt: 2}}>
      <Grid xs={6}> </Grid>
      <Grid xs={1}>
        { loading && <CircularProgress /> }
      </Grid>
      <Grid xs={5}> </Grid>

      <Grid xs={3}></Grid>
      <Grid xs={6}>
      {!loading && <Card sx={{ px: 2, p: 2 }}>
      <Grid container spacing={2} sx={{mt: 2}}>
        <Grid xs={4}></Grid>
        <Grid xs={3}>
          <img src={logo} alt='Evoke Insight Logo' height={100} />
        </Grid>
        <Grid xs={5}></Grid>
      </Grid>

      <Grid container spacing={2} sx={{mt: 2}}>
        <Grid xs={2}></Grid>
        <Grid xs={8}>
          <Typography variant='h5' sx={{ mb: 1.5, mt: 1, fontSize: 12, lineHeight: 2 }} color="text.secondary" fontWeight={100}>
            Create your workspace
          </Typography>

        </Grid>
        <Grid xs={2}></Grid>
      </Grid>

      {showForm && <Grid container spacing={2} sx={{mt: 0}}>
        <Grid xs={1}>
        </Grid>
        <Grid xs={10}>
          {/* <Box sx={{color: purple[700]}}>{message}</Box> */}
          <Box sx={{my: 1}}>
            { message && <Alert severity={error ? 'error' : 'success'}>
            {message}
          </Alert>}
        </Box>

          <Box sx={{color: red[300], mt: 2}}>{error}</Box>
          <TextField
          id="company_name"
          label="Company/Workspace name"
          name="company_name"
          type='text'
          size="small"
          fullWidth
          sx={{mt: 3}}
          value={payload.company_name}
          onChange={handleChange}
        />

        <TextField
          id="password"
          label="Password********"
          name="password"
          type='password'
          size="small"
          fullWidth
          sx={{mt: 3}}
          value={payload.password}
          onChange={handleChange}
        />

        <TextField
          id="password2"
          label="Re-type password********"
          name="password2"
          type='password'
          size="small"
          fullWidth
          sx={{mt: 3}}
          value={payload.password2}
          onChange={handleChange}
        />

        </Grid>
        <Grid xs={1}>
        </Grid>

        <Grid xs={4}></Grid>
        <Grid xs={8}>
          <Button variant="contained" onClick={validateForm}>
            { loading && <CircularProgress color='inherit' size={14} />}
              &nbsp; Create Workspace
            </Button>
        </Grid>
      </Grid>}

      </Card>}
      </Grid>
      <Grid xs={3}> </Grid>
    </Grid>
    </Container>
)}

export default CreateTeam;