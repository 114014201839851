import React, { useState } from 'react';
import {Card, TextField, Button, Container, Alert, Box, Typography } from '@mui/material';
import axios from 'axios';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';

const Signup = ({ setView }) => {
  const API_ENDPOINT = process.env.REACT_APP_RESULT_API_ENDPOINT;

  const [payload, setPayload] = useState({
    email: '',
    first_name: '',
    last_name: '',
  });

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');


  const handleChange = e => {
    setPayload({ ...payload, [e.target.id]: e.target.value });
  }

  const registerApi = async() => {
    setError(false);
    setMessage('');
    try {
      const { data } = await axios.post(`${API_ENDPOINT}/auth/register`, payload);
      setMessage(`An email has been sent to your email address ${payload.email}.\nClick on the link in the email to verify your email address`);
      setLoading(false);
      setPayload({ email: '', first_name: '', last_name: '' })
      // window.location.reload();
    } catch (error) {
      let errMsg = 'You are unable to register at the moment. Please try again later.';
      if (error?.response?.data) {
        errMsg = error.response.data.message;
      }
      setLoading(false);
      setError(true);
      setMessage(errMsg);
    }
  }


  const validateForm = () => {
    setError(false);
    setMessage('')
    let value
    if (!payload.email) {
      value = 'Email is required';
      setMessage(value);
      return setError(true);
    }
    if (!payload.first_name) {
      value = 'First name is required';
      setMessage(value);
      return setError(true);
    }
    if (!payload.last_name) {
      value = 'Last name is required';
      setMessage(value);
      return setError(true);
    }

    setLoading(true)
    registerApi();
  }

return (
    <Container>
      <Typography variant='h6' sx={{ mb: 1.5, mt: 1, fontSize: 12, lineHeight: 2 }} color="text.secondary" fontWeight={100}>
        Sign up
      </Typography>

      <Box sx={{my: 3}}>
        { message && <Alert severity={error ? 'error' : 'success'}>
        {message}
      </Alert>}
      </Box>
      
      <Grid container spacing={2} sx={{mt: -2}}>
        <Grid xs={10}>
          { (
            <TextField
            id="email"
            label="Email address"
            name="email"
            type='email'
            size="small"
            fullWidth
            value={payload.email}
            onChange={handleChange}
          />
          )}
        </Grid>
        <Grid xs={1}>
        </Grid>

        <Grid xs={1}>
        </Grid>
        <Grid xs={10}>
        <TextField
          id="first_name"
          label="First name"
          name="first_name"
          type='text'
          size="small"
          fullWidth
          value={payload.first_name}
          onChange={handleChange}
        />
        </Grid>
        <Grid xs={1}>
        </Grid>

        <Grid xs={1}>
        </Grid>
        <Grid xs={10}>
        <TextField
          id="last_name"
          label="Last name"
          name="last_name"
          type='text'
          size="small"
          fullWidth
          value={payload.last_name}
          onChange={handleChange}
        />
        </Grid>
        <Grid xs={1}>
        </Grid>

        <Grid xs={4}></Grid>
        <Grid xs={8}>
          <Button variant="contained" onClick={validateForm}>
            { loading && <CircularProgress color='inherit' size={14} />}
              &nbsp; Sign up
            </Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid xs={10}>Already have an account?
          <Button variant="text" onClick={()=>setView('login')}>Login here</Button>
        </Grid>
      </Grid>

    </Container>
)}

export default Signup;