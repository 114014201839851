import React, { useState, useEffect } from 'react';
import { Container, Card, TextField, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import { updateValue } from '../../store/reducers/settingsReducers';
import { updatePassword } from '../../store/actions/settingsActions';
import { updateUserValue } from '../../store/reducers/userReducers';


const ChangePassword = ({ hasPassword, team_id }) => {
  const state = useSelector((state) => state.settings.password);
  // const team = useSelector((state) => state.users.team);
  const dispatch = useDispatch();

  const setHasPassword = () => {
    if (password.hasPassword) {
      dispatch(updateUserValue({ feature: 'user', key: 'hasPassword', value: true }))
    }
  }

  useEffect(()=> {
    setHasPassword();
  }, [state.loading]);

  const [password, setPassword] = useState({
    current_password: '',
    new_password: '',
    new_password2: '',
  });

  const handleChange = e => {
    setPassword({ ...password, [e.target.id]: e.target.value });
  }

  const validateForm = () => {
    const key = 'message';
    const feature = 'password';
    let value
    dispatch(updateValue({key, value: '', feature }));
    if (!password.new_password || password.new_password.length < 8) {
      value = 'New password should be atleat 8 characters';
      dispatch(updateValue({key, value, feature }));
      return;
    }
    if (password.new_password !== password.new_password2) {
      value = 'Passwords do not match';
      dispatch(updateValue({key, value, feature }));
      return;
    }

    if (hasPassword && (!password.current_password || password.current_password.length < 8)) {
      value = 'Valid current password required';
      return dispatch(updateValue({key, value, feature }));
    }

    const payload = { team_id, password: password.new_password, current_password: password.current_password }
    dispatch(updateValue({key: 'loading', value: true, feature }));
    dispatch(updatePassword(payload));
  }

return (
  <Container>
    <Card sx={{ px: 3, pb: 4 }}>
      <b><h2>{hasPassword ? 'Update password' : 'Secure your account'}</h2></b>
      <div style={{ color: 'red', fontSize: 15 }}>{state.message}</div>

      <Grid container spacing={2} sx={{mt: 2}}>
        <Grid xs={4}>
          { hasPassword && (
          <Typography sx={{mt: 2}}>
            <b>Current password</b>
          </Typography>
          )}
        </Grid>
        <Grid xs={8}>
          { hasPassword && (
            <TextField
            id="current_password"
            label="Current Password"
            name="current_password"
            type='password'
            color="secondary"
            size="small"
            value={password.current_password}
            onChange={handleChange}
          />
          )}
        </Grid>
        <Grid xs={4}>
          <Typography sx={{mt: 2}}>
          <b>New password</b>
          </Typography>
        </Grid>
        <Grid xs={8}>
        <TextField
          id="new_password"
          label="New Password"
          name="new_password"
          type='password'
          color="secondary"
          size="small"
          value={password.new_password}
          onChange={handleChange}
        />
        </Grid>

        <Grid xs={4}>
          <Typography sx={{mt: 2}}>
            <b>Re-type new password</b>
          </Typography>
        </Grid>
        <Grid xs={8}>
        <TextField
          id="new_password2"
          label="Re-type new password"
          name="new_password2"
          type='password'
          size="small"
          color="secondary"
          value={password.new_password2}
          onChange={handleChange}
        />
        </Grid>

        <Grid xs={4}></Grid>
        <Grid xs={8}>
          <Button variant="outlined" color='secondary' sx={{mt: 3}} onClick={validateForm}>
            { state.loading && <CircularProgress color='inherit' size={14} />}
              &nbsp; Update password
            </Button>
        </Grid>

      </Grid>
    </Card>
  </Container>
)}

export default ChangePassword;