import React, { useEffect, useState } from 'react';
import {Box, Container} from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import { fetchResource } from '../../store/actions/resourceActions';
import { useTheme, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getPlatforms } from '../../store/reducers/resourceReducers';
// import ResourceTable from '../../components/ResourceTable';
import PlatformCard from '../../components/PlatformCard';
import Grid from '@mui/material/Unstable_Grid2';

const resource = 'platforms';

const Platforms = (props) => {
  // const theme = useTheme();
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 300,
        ld: 400,
        nd: 600,
        700: 700,
        820: 820,
        900: 900,
        1200: 1200,
        1400: 1400,
        md: 800,
        od: 1000,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const state = useSelector((state) => state.resources.platforms);
  const team = useSelector((state) => state.users.team);
  const [screenSize, setScreenSize] = useState(0);
  const dispatch = useDispatch();
  const appName = process.env.REACT_APP_APP_NAME;
  const up7 = useMediaQuery(theme.breakpoints.up(700));
  const down7 = useMediaQuery(theme.breakpoints.down(700));

  const up8 = useMediaQuery(theme.breakpoints.up(820));
  const up9 = useMediaQuery(theme.breakpoints.up(900));
  const up12 = useMediaQuery(theme.breakpoints.up(1200));
  const up14 = useMediaQuery(theme.breakpoints.up(1400));


  const steps = (platform) =>([
    `Find and add ${appName} bot to your ${platform} workspace.`,
    `Send 'hi' to the bot and it will respond with a code`,
    `Click on the link account button above and send the code provided by the bot`,
    `Click Submit and you are done!`
  ]);

  useEffect(()=> {
    dispatch(fetchResource({ resource, team_id: team.team_id, skip: state.skip, limit: state.limit }));
  }, []);

  useEffect(()=> {
    getScreenSize()
  }, []);


  const getScreenSize = () => {
    let val;
    if (up7) {
      val = 700;
    }

    if (down7) {
      val = 690;
    }
    
    if (up8) {
      val = 820;
    }
    if (up9) {
      val = 920;
    }

    if (up12) {
      val = 1200;
    }

    if (up14) {
      val = 1400;
    }
    setScreenSize(val)
    return val
  }

  const SlackButton = () => <Box><a href="https://slack.com/oauth/v2/authorize?client_id=5576615948067.5576899212882&scope=app_mentions:read,im:history,links:read,chat:write,files:read,files:write,im:read,mpim:history,channels:join,channels:history,groups:history,chat:write.customize&user_scope="><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a></Box>

  return (
    <Box
      // component="mainT"
      // className='page_containerY'
        sx={{ p: 1, mt: 6, flexGrow: 1 }}
        // flexGrow: 12
        >
        <b><h2>Platforms</h2></b>

        <Box>
        {/* columns={{ xs: 4, sm: 6, md: 12 }} */}
        {/* spacing={2} */}
        {/* , justifyContent: { xs: 'center', md: 'right' }, */}
        <Grid container spacing={2} columns={{ xs: 4, sm: 6, md: 12 }} xs={12} sm={12} md={12} sx={{my: 0}} >
          { state.defaultPlatforms && Object.keys(state.defaultPlatforms).map(platform =>(
          <Grid item key={platform} sm={12} xs={12} md={[920, 1200].includes(screenSize) ? 5 : 3} sx={{mr: 0}}>
            <PlatformCard
              platform={state.defaultPlatforms[platform]}
              team_id={team.team_id}
              name={platform}
              steps={steps}
            />

            {/* {`${JSON.stringify(state.defaultPlatforms[platform].teamInfo)}`} */}
          </Grid>
          ))}
        </Grid>
        </Box>



    </Box>
  )
}

export default Platforms;