import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function PlansCard(props) {
  const { plan, user, currentPlan, pickPlan } = props;

  let nf = new Intl.NumberFormat('en-US');
  
  return (
    <Box sx={{ minWidth: 205 }}>
      <Card variant="outlined" sx={{ backgroundColor: props.currentPlan ? '#E3F4F4' : '#F8F6F4', minHeight: 290 }}>
        {/* {card(props)} */}
          <CardContent>
            <Typography variant="h5" component="div">
              <b>{plan.name}</b>
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {plan.description}
            </Typography>

            <Typography sx={{ mb: 1.25 }} color="text.secondary">
              {bull} Row limit {nf.format(plan.row_limit)}
            </Typography>

            <Typography sx={{ mb: 1.25 }} color="text.secondary">
              {bull} Column limit {plan.column_limit}
            </Typography>

            <Typography sx={{ mb: 1.25 }} color="text.secondary">
              {bull} Monthly request limit {nf.format(plan.monthly_request_limit)}
            </Typography>

            <Typography sx={{ mb: 1.25 }} color="text.secondary">
              {bull} ${nf.format(plan.price)} USD / month
            </Typography>

            <Typography variant="body2">
              <b></b>
            </Typography>

          </CardContent>
          {parseInt(plan.id) > 1 && <CardActions sx={{ height: 40 }}>
            <Button onClick={()=>pickPlan(plan)}>Select plan</Button>
          </CardActions>}
      </Card>
    </Box>
  );
}