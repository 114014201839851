import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { getHeaders } from './utils';
const API_ENDPOINT = process.env.REACT_APP_RESULT_API_ENDPOINT;

const defaultError = { message: 'Something went wrong, please try again later' };

const errMessage = err => {
  if (!err.response) return err.message;
  return err.response.data;
}

export const fetchResource = createAsyncThunk('resource/fetchResources',
  async ({ team_id, skip, limit, resource }, { rejectWithValue }) => {
    const headers = getHeaders(team_id);
    const response = await axios.get(
      `${API_ENDPOINT}/teams/resource?resource=${resource}&skip=${skip}&limit=${limit}`,
      headers
    );
    return response.data
  }
);

export const connectPlatform = createAsyncThunk('resource/linkPlatform',
  async ({ team_id, code, platform }, { rejectWithValue }) => {
    if (!code || code.length < 10) return rejectWithValue({ message: 'Provide a valid code' });
    const headers = getHeaders(team_id);

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/platform/verify_code/${code}`,
        {platform},
        headers
      );
      return response.data
        
    } catch (error) {
      return rejectWithValue(errMessage(error));      
    }
  }
);
