import React, { useState, useEffect } from 'react';
import { Container, Avatar, Paper, Card, CardContent, CardMedia, CardActionArea, TextField, Button, Box, Alert, Typography } from '@mui/material';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Typography from '@mui/material/Typography';
// import { CardActionArea } from '@mui/material';

import { useNavigate, useLocation } from "react-router-dom";
// import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import evokeLogo2 from '../../../components/media/logos/evoke-logo-no-background.svg';
// import { fontWeight, height, maxWidth, minHeight, width } from '@mui/system';
import { useParams, useSearchParams } from "react-router-dom";

// const RedirectSlack = props => {


const DiscordOauth = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false);
  const [access_token, setAccess_token] = useState('');
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [servers, setServers] = useState([]);
  const [server, setServer] = useState({});
  const [page, setPage] = useState({ params });
  // http://localhost:3001/oauth/landing#token_type=Bearer&access_token=PZW75QKppiDmLwa53TAIOvKi6GeMEG&expires_in=604800&scope=applications.commands+guilds+identify+email
  // http://localhost:3001/oauth/landing#token_type=Bearer&access_token=PZW75QKppiDmLwa53TAIOvKi6GeMEG&expires_in=604800&scope=email+guilds+identify+applications.commands
  // http://localhost:3001/oauth/landing#token_type=Bearer&access_token=dXZKzvD2Iunt4rftX7F32UhegoISFx&expires_in=604800&scope=guilds+applications.commands+email+connections+guilds.members.read+identify
  // http://localhost:3001/oauth/discord#error=access_denied&error_description=The+resource+owner+or+authorization+server+denied+the+request

  const checkToken = () => {
    let ux = localStorage.getItem('user');
    if (ux) {
      try {
        ux = JSON.parse(ux);
        if (ux.token) {
          navigate(0);
        }
      } catch (error) {
        
      }
    }

    const hashArr = hash.split('&');
    const access_index = hashArr.findIndex(h => h.substr(0, 12) === 'access_token');
    if (access_index < 0) {
      setError(true)
      setLoading(false)
      setMessage('Login failed');
      return
    }
  
    const access_string = hashArr[access_index];
    if (!access_string) {
      setError(true)
      setMessage('Login failed');
      return

    }
    const ac_token = access_string.split('=')[1];
    setAccess_token(ac_token);
    // setAccess_token(access_string.substr(13, access_string.length));

    login(ac_token);
  }

  const login = async (access_token, view = 'preview') => {
    setLoading(true);
    const url = process.env.REACT_APP_RESULT_API_ENDPOINT + '/auth/social/discord';
    const payload = {access_token, provider: 'discord', view, server};
    try {
      const { data } = await axios.post(url, payload);

      if (data.login) {
        localStorage.setItem('user', JSON.stringify(data.user));
        setMessage('Login successful');
        navigate(0);
        // window.location.reload();
      }

      if (data.discordUser) {
        setUser(data.discordUser);
        if (data.servers && data.servers.length) {
          setServers([...data.servers]);
        }  
      }
      setLoading(false);
    } catch (error) {
      setError(true)
      setMessage(error.response.message || 'Error verifying login')
      setLoading(false);
    }
  }

  useEffect(()=> {
    checkToken();
  }, [])

  const selectServer = (s, ignore) => {
    if (ignore) return;
    if (Number(server.id) === Number(s.id)) {
      setServer({});
      return;
    }
    setServer(s)
  }


const ServerCard = ({ server: s, selectServer, ignore }) => {
  const { id, name, icon } = s;
    return (
      <Paper elevation={server.id == id ? 24 : 1} sx={{p: 0, maxWidth: 250}}>
      <Card sx={{ maxWidth: 250, backgroundColor: server.id == id ? '#e3faef' : null }} onClick={()=>selectServer(s, ignore)} >
        <CardActionArea sx={{mt: 0}}>
          <Box align="center" sx={{mt: 3}}>
            { icon && <Avatar src={`https://cdn.discordapp.com/icons/${id}/${icon}`} sx={{height: 56, width: 56 }} alt={name} /> }
            { !icon && <Avatar alt={name} sx={{height: 56, width: 56 }}>{name}</Avatar> }
          </Box>
          
          <CardContent sx={{mt: 2, height: 110}} align="center">
            <Typography gutterBottom variant="h6" component="div">
              {name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      </Paper>
    );
  }

// "https://cdn.discordapp.com/avatars/991043953648365570/a9a65cfab0ef352f7db12d12d2faaa15"

  return (
  <Box>
    <Box>
      {/* <Box sx={{my: 3, mx: 2}}>
        <img src={evokeLogo2} alt='Evoke Insight Logo' height={35} />
      </Box> */}

    <Container>
      {loading && <Box sx={{my: 3}}>
        <Grid container spacing={2} sx={{mt: 2}}>
          <Grid xs={6}></Grid>
          <Grid xs={2}>
            <CircularProgress />
          </Grid>
          <Grid xs={4}></Grid>
        </Grid>

        <Grid container spacing={2} sx={{mt: 2}}>
          <Grid xs={4}></Grid>
          <Grid xs={6}>
            <Typography variant='h3' sx={{ mb: 1.5, mt: 1, fontSize: 12, lineHeight: 2 }} color="text.secondary" fontWeight={100}>
              Give us a minute!
            </Typography>
          </Grid>
          <Grid xs={2}></Grid>
        </Grid>

      </Box>}
    </Container>

    <Container>
      {!loading && <Box>
      
      <Box sx={{mt: 5}}>
        <Grid container spacing={2} sx={{mt: 2}}>
          <Grid xs={4}></Grid>
          <Grid xs={6}>
          { message && <Alert severity={error ? 'error' : 'success'}>
            <Typography variant='h5' sx={{ mb: 1.5, mt: 1, fontSize: 12, lineHeight: 2 }} color="text.secondary" fontWeight={100}>
            {message}
            </Typography>
          </Alert>}
          </Grid>
          <Grid xs={2}></Grid>
        </Grid>

        {!error && <Container>
        <Box>
          <Grid container spacing={2} sx={{mt: 2}}>
            <Grid xs={2}></Grid>
            <Grid xs={8} align="center">
              <Box><Avatar src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}`} sx={{height: 56, width: 56 }} alt={user.name} /></Box>
              <Typography variant='body' sx={{ mb: 1.5, mt: 1, fontSize: 24, lineHeight: 2 }} color="text.secondary" fontWeight={100}>
                Welcome {user.global_name || user.username}
              </Typography>
              {user.email && <Box>
                <Typography variant='body' sx={{ mb: 1.5, fontSize: 18, lineHeight: 2 }} color="text.secondary" fontWeight={100}>
                  ({user.email})
                </Typography>
              </Box>}
            </Grid>
            <Grid xs={2}></Grid>
          </Grid>
        </Box>


        {servers[0] && <Box>
          <Grid container spacing={2} sx={{mt: 2}}>
            <Grid xs={3}></Grid>
            <Grid xs={7} align="center">
              <Typography variant='body' sx={{ mb: 1.5, mt: 1, fontSize: 24, lineHeight: 2, fontWeight: 400 }} color="text.secondary" fontWeight={100}>
                Select a server you will like to link as well.
              </Typography>
            </Grid>
            <Grid xs={2}></Grid>
          </Grid>

          <Box>
            <Grid container spacing={2} sx={{mt: 2}}>
              { servers.map(server =><Grid key={server.id} xs={12} md={3}>
                <ServerCard selectServer={selectServer} server={server} />
                </Grid>) }
            </Grid>

          </Box>
        </Box>}

        </Container>}
      </Box>


      {!error && <Box sx={{my: 10, backgroundColor: '#f8f7fa', minHeight: 400, py: 5}}>
        <Grid container spacing={2} sx={{mt: 2}}>
          <Grid key={server.id} md={1}>
          </Grid>
          <Grid xs={12} md={server.id ? 3 : 2}>
          {server.id && <Box>
                <ServerCard selectServer={selectServer} ignore={true} server={server} />
                </Box>}
            </Grid>
          
          <Grid xs={12} md={5}>
            <Box sx={{px: 3, height: 136}} align="center">
            <Avatar src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}`} sx={{height: 56, width: 56 }} alt={user.name} />
            {server.id && <Typography variant='body' sx={{ mb: 1.5, mt: 1, fontSize: 24, lineHeight: 2 }} color="text.secondary" fontWeight={100}>
                Your Evoked account will also be linked to <b>{server.name}</b> server
              </Typography>}

              {!server.id && <Box sx={{ mt: 4}}><Typography variant='body' sx={{ mb: 1.5, fontSize: 24, lineHeight: 2 }} color="text.secondary" fontWeight={100}>
                Proceed with your discord account {user.global_name}
              </Typography></Box>}
            </Box>

            <Box sx={{mt: 10}} align="center"> 
              <Button onClick={()=>login(access_token, 'submit')} variant="contained" color="secondary" size="large">Proceed</Button>
            </Box>
          </Grid>
        </Grid>
      </Box>}


      </Box>}


    </Container>
    </Box>
  </Box>)

}

export default DiscordOauth;