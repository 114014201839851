import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { getHeaders } from './utils';
const API_ENDPOINT = process.env.REACT_APP_RESULT_API_ENDPOINT;
const defaultError = { message: 'Something went wrong, please try again later' };
const loggedOutError = { message: "You are logged out!" };

export const getUsers = createAsyncThunk('users/getUsers',
  async (payload, { rejectWithValue }) => {
  const { team_id } = payload;
  const headers = getHeaders(team_id);
  try {
    const { data } = await axios.get(`${API_ENDPOINT}/teams/users`, headers);
    return data
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getUser = createAsyncThunk('users/getUser',
  async (id, { rejectWithValue }) => {
  const headers = getHeaders();
  if (!headers) return rejectWithValue("You are logged out!");
  try {
    const { data } = await axios.get(`${API_ENDPOINT}/users/${id}`, headers);
    return data
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


export const inviteUser = createAsyncThunk('users/inviteUser',
  async (payload, { rejectWithValue }) => {
  const { team_id } = payload;
  const headers = getHeaders(team_id);

  if (!headers) return rejectWithValue(loggedOutError);

  try {
    const { data } = await axios.post(`${API_ENDPOINT}/users/invite`, payload, headers);
    return data
  } catch (error) {
    return rejectWithValue(error?.response?.data || defaultError);
  }
});

export const removeUser = createAsyncThunk('users/removeUser',
  async (payload, { rejectWithValue }) => {
  const { team_id } = payload;
  const headers = getHeaders(Number(team_id));

  if (!headers) return rejectWithValue(loggedOutError);
  try {
    const { data } = await axios.put(`${API_ENDPOINT}/users/remove/${payload.user_team_id}`, payload, headers);
    return data
  } catch (error) {
    return rejectWithValue(error?.response?.data || defaultError);
  }
});

// Profile
export const getMe = createAsyncThunk('users/getMe',
  async (id, { rejectWithValue }) => {
  const headers = getHeaders();
  if (!headers) return rejectWithValue(loggedOutError);

  try {
    const { data } = await axios.get(`${API_ENDPOINT}/auth/me`, headers);
    return data
  } catch (error) {
    // console.log('----userAcshion: ', error.message);
    return rejectWithValue(error.response || error.message);
  }
});


export const updateUserProfile = createAsyncThunk('users/updateUserProfile',
  async (payload, { rejectWithValue }) => {
  const headers = getHeaders();
  if (!headers) return rejectWithValue(loggedOutError);

  try {
    const { data } = await axios.put(`${API_ENDPOINT}/users/me`, payload);
    return data
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
