import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectComponent({ options, handleChange, data, targetId }) {

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">{targetId}</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id={targetId}
        value={data}
        label={targetId}
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>

        {
          options && options.length && options.map((option, index) => (
            <MenuItem key={index} value={option.value}>{option.text}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}