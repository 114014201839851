import * as React from 'react';
import Button from '@mui/material/Button';
import {TextField, CircularProgress, Typography} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function LinkPlatformDialog(props) {
  const { open, handleClose, handleClick, platform, loading, message, code, handleChange } = props;
  // const [code, setCode] = React.useState('');

  // const handleChange = (e) => {
  //   setCode(e.target.value)
  // };

  return (
    <div>
      <Dialog open={open} onClose={()=>handleClose(!open)}>
        <DialogTitle sx={{mt: -1, fontSize: 20}}><b>Connect to {platform.name}</b></DialogTitle>
        
        <DialogContent>
          <DialogContentText sx={{mt: 1, fontSize: 15}}>
            Paste the code provided to you by the bot in the textbox below.
          </DialogContentText>
          
          <Typography sx={{mt: 1, color: 'red'}}>{message}</Typography>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Code"
            type="text"
            multiline
            rows={3}
            fullWidth
            variant="filled"
            value={code}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose(!open)}>Cancel</Button>
          <Button variant="contained" onClick={()=>handleClick(code)}>
          { loading && <CircularProgress color='inherit' size={14} />}
          &nbsp; Connect
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}