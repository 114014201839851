import { createSlice } from '@reduxjs/toolkit';
import { fetchRequests, clearResult } from '../actions/requestActions';

export const requestSlice = createSlice({
  name: 'requests',
  initialState: {
    requests: [],
    skip: 0,
    limit: 20,
    total: 0,
    loading: true,
    records: {},
    hasMore: false,
    page: 0,
    error: false,
    lastUpdated: Date.now(),
  },
  reducers: {
    getRequests: (state) => {
      state.request = { name: 'Kay' };
    },
    getRequest: (state, action) => {
      state.users.push(action.payload);
    },
    setTeam: (state, team_id) => {
      state.team_id = team_id;
    },
    clearError: (state) => {
      state.error = false;
      state.message = '';
      state.loading = false;
    }
  },
  extraReducers(builder) {
    // omit posts loading reducers
    builder.addCase(fetchRequests.fulfilled, (state, { payload }) => {
      if (Date.now() - state.lastUpdate < 2 && state.requests.length > 0) return;
      // We can directly add the new post object to our posts array
      // const records = state.requests.concat(action.payload);
      // state.requests.push(action.payload);
      // console.log('------------>>>>>>> request action: ', payload)
      const { requests, skip, limit, total } = payload;
      if (skip === state.skip) return;
      if (requests.length && requests.length >= 20) {
        state.hasMore = true;
      }
      state.page = parseInt(state.page) + 1;
      state.requests = [ ...state.requests, ...requests];
      state.skip = skip;
      state.limit = limit;
      state.total = total;
      state.lastUpdated = Date.now()
      state.loading = false;
    });
    builder.addCase(clearResult.fulfilled, (state, { payload: { task, message } }) => {
      state.error = false;
      state.loading = false;
      if (!task || !task.id) window.location.reload();
      const objIndex = state.requests.findIndex(obj => Number(task?.id) === Number(obj.id));
      if (objIndex >= 0) {
        state.requests[objIndex] = task;
      }
      state.message = message;
    });

    builder.addCase(clearResult.pending, (state, { payload }) => {
      state.error = false;
      state.loading = true;
      state.message = ''
    });

    builder.addCase(clearResult.rejected, (state, { payload: { message } }) => {
      state.loading = false;
      state.error = true;
      state.message = message;
    });

  }
});

export const { getRequests, getRequest, setTeam, clearError } = requestSlice.actions

export default requestSlice.reducer;
