import React, { useState, useEffect } from 'react';
import { Container, Box, Alert, Typography, Button } from '@mui/material';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Typography from '@mui/material/Typography';
// import { CardActionArea } from '@mui/material';

import { useNavigate, useLocation, useParams, useSearchParams, createSearchParams } from "react-router-dom";
// import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import evokeLogo2 from '../../../components/media/logos/evoke-logo-no-background.svg';
// import { fontWeight, height, maxWidth, minHeight, width } from '@mui/system';
// import {  } from "react-router-dom";

// const RedirectSlack = props => {

// http://localhost:3001/oauth/slack?code=661913844195.7559290718818.e8e9bd0e8b92e4a2aa32b096efdae9d2e41d7a5073fdcfeb39fc0a38bdd03340&state=

const SlackOauth = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams(search);
  const code = searchParams.get('code');
  const codeState = searchParams.get('state');
  const access = searchParams.get('access');

  const [message, setMessage] = useState('')
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [login, setLogin] = useState(false);
  const [account, setAccount] = useState({});
  const [server, setServer] = useState({});
  const loginNavPath = {
    pathname: window.location.href,
    search: createSearchParams({ access: new Date() }).toString()
  }
  const accessPath = '/oauth/slack-access-checking?access=true';

  let fire = 0

  const checkToken = () => {
    // navigate(accessPath)
    if (!code) {
      setError(true)
      setLoading(false)
      setMessage('Login failed');
      return
    }
  
    fire++;
    setLoading(true);
    loginFn(code);
  }

  // http://localhost:3001/oauth/slack?code=661913844195.7612322419156.cda3bbdbe7a9a6798a71ef32aa65f4c16862a34c361b20368bd5ac468e6b44ad&state=install
  const loginFn = async (code, view = 'preview') => {
    if (fire > 1) return;
    setLoading(true);
    const url = process.env.REACT_APP_RESULT_API_ENDPOINT + '/auth/social/slack';
    const redirect_uri = process.env.REACT_APP_SLACK_INSTALL_REDIRECT_URL;
    const payload = {code, provider: 'slack', view, redirect_uri, state: codeState };
    try {
      const { data } = await axios.post(url, payload);
      if (data.login) {
        localStorage.setItem('user', JSON.stringify(data.user));
        setLogin(true);
        if (codeState === 'login') {
          navigate(accessPath);
        }
        setAccount(data.account);
        if (!data.account || data.account.new_team) {
          navigate(accessPath)
        }
      }

      setLoading(false);
    } catch (error) {
      setError(true)
      setMessage(error.response.message || 'Error verifying login')
      setLoading(false);
    }
  }

  useEffect(()=> {
    checkToken();
  }, [])

  const selectServer = (s, ignore) => {
    if (ignore) return;
    if (Number(server.id) === Number(s.id)) {
      setServer({});
      return;
    }
    setServer(s)
  }


// "https://cdn.discordapp.com/avatars/991043953648365570/a9a65cfab0ef352f7db12d12d2faaa15"

  return (
  <Box>
    <Box>
      {/* <Box sx={{my: 3, mx: 2}}>
        <img src={evokeLogo2} alt='Evoke Insight Logo' height={35} />
      </Box> */}

    <Container>
      {loading && <Box sx={{my: 3}}>
        <Grid container spacing={2} sx={{mt: 2}}>
          <Grid xs={6}></Grid>
          <Grid xs={2}>
          <CircularProgress />
          </Grid>
          <Grid xs={4}></Grid>
        </Grid>

        <Grid container spacing={2} sx={{mt: 2}}>
          <Grid xs={4}></Grid>
          <Grid xs={6}>
            <Typography variant='h3' sx={{ mb: 1.5, mt: 1, fontSize: 12, lineHeight: 2 }} color="text.secondary" fontWeight={100}>
              Give us a minute!
            </Typography>
          </Grid>
          <Grid xs={2}></Grid>
        </Grid>

      </Box>}
    </Container>

    <Container>
      {!loading && <Box>
      
      <Box sx={{mt: 5}}>

        <Grid container spacing={2} sx={{mt: 2}}>
          <Grid xs={3}></Grid>
          <Grid xs={6}>
          { message && <Alert severity={error ? 'error' : 'success'}>
            <Typography variant='h6' sx={{ mb: 1.5, mt: 1, fontSize: 12, lineHeight: 2 }} color="text.secondary" fontWeight={100}>
            {message}
            </Typography>
          </Alert>}

          <Box>
          {login && <Box sx={{mt: 5}}>
              {account.new_team && <Box>
                <Typography variant='h5' sx={{ mb: 1.5, mt: 1, fontSize: 12, lineHeight: 2 }} color="text.secondary" fontWeight={100}>
                <b>Evoke Insight bot</b> successfully installed to your <b>{account.new_team}</b> workspace.
                </Typography>
              </Box>}

              <Box sx={{mt: 3}}>
              {account.new_user &&<Typography variant='h5' sx={{ mb: 1.5, mt: 1, fontSize: 12, lineHeight: 2 }} color="text.secondary" fontWeight={100}>
                  You account <b>{account.new_user}</b> has been linked.
                </Typography>}
              </Box>
              { <Button sx={{mt: 6}} variant='outlined' onClick={()=>navigate(0)}>Go to Dashboard</Button> }
            </Box>}
          </Box>
          </Grid>
          <Grid xs={2}></Grid>
        </Grid>

      </Box>

      </Box>}


    </Container>
    </Box>
  </Box>)

}

export default SlackOauth;