import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

const PageNotFound = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [view, setView] = useState(0);
  const checkAccess = () => {
    const user = localStorage.getItem('user');
    if (!user && location.pathname.substring(0, 10) === '/dashboard') {
      // alert(location.pathname.substring(0, 10));
      return navigate('/');
    }
    setView(404);
  }

  useEffect(()=>{
    checkAccess()
  }, []);

  return (
    <Container>
      { view !== 404 ? null : (
      <Container sx={{ mt: 30 }}>
        <Grid container spacing={2} sx={{mt: 2}}>
        <Grid xs={3}> </Grid>
          <Grid xs={6}>
            <Typography sx={{ fontSize: 50, fontWeight: 800 }}>
              404 - Page not found!
            </Typography>
          </Grid>
        <Grid xs={3}> </Grid>
        </Grid>
      </Container>

      )}
    </Container>
  )

}

export default PageNotFound;