import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";


const DashboardOathLanding = () => {
  const navigate = useNavigate();
  
  const manageAccess = () => {
    const user = localStorage.getItem('user');
    if (!user) return navigate('/');
    navigate('/dashboard/platforms');
  }

  useEffect(()=> {
    manageAccess()
    // navigate('/dashboard/platforms');
  }, []);
}

export default DashboardOathLanding;