import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import Toolbar from '@mui/material/Toolbar';
import PrimaryAppBar from './components/PrimaryAppBar';
import { useSelector } from "react-redux";
import GroupIcon from '@mui/icons-material/GroupOutlined';
import PaymentsIcon from '@mui/icons-material/Payments';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
// import 
import './App.css';
import {
  Routes,
  Route,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard/index";
import Login from "./pages/Sites/login";
import Payments from "./pages/Dashboard/Payments";
import UsersDashbaord from "./pages/Dashboard/Users";
import Signup from "./pages/Sites/Signup";
import RequestsPage from './pages/Dashboard/Requests';
import Platforms from './pages/Dashboard/Platforms';
import Settings from './pages/Dashboard/Settings';
import Team from './pages/Dashboard/Team';
import DashboardOathLanding from './pages/Dashboard/oath/Landing';
import Profile from './pages/Dashboard/Profile';
// import { useLocation } from 'react-router-dom';


const drawerWidth = 240;

// Responsive drawer
export default function AuthenticatedApp(props) {
  const navigate = useNavigate();
  const location = useLocation()
  const state = useSelector((state) => state.users);
  const [activeTab, setActiveTab] = React.useState('Platforms')
  const pathname = location.pathname;
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const tabs = [
    { name: 'Platforms', Icon: IntegrationInstructionsOutlinedIcon },
    { name: 'Team', Icon: GroupsOutlinedIcon },
    { name: 'Requests', Icon: WorkHistoryOutlinedIcon},
    { name: 'Users', Icon: GroupIcon },
    { name: 'Payments', Icon: PaymentsIcon },
    { name: 'Settings', Icon: SettingsOutlinedIcon },
  ];

  const navigateToPage = (tabName) => {
    // console.log('useNavigate_____', tabName);
    navigate(`/dashboard/${tabName.toLowerCase()}`)
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const manageAccess = () => {    
    if (!state.loading && state.user && !state.teams.length) {
      if (pathname !== '/') return navigate('/');
    }
  }

  useEffect(()=> {
    manageAccess()
  }, []);

  const getActiveTab = () => {
    // const pathStr = pathname.substring (11, 16)
    const pathArr = pathname.split('/')
    // alert(pathArr[2]);
    return pathArr[2];
  }

  // useEffect(()=> {
  //   getActiveTab()
  // }, [pathname]);


  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {tabs.map((tab, index) => (
          <ListItem key={tab.name} disablePadding
          onClick={()=>navigate(`/dashboard/${tab.name.toLowerCase()}`)}>
            <ListItemButton sx={{backgroundColor: getActiveTab()=== tab.name.toLowerCase() ? '#ededed': ''}}>
              <ListItemIcon>
                <tab.Icon color={getActiveTab() === tab.name.toLowerCase() ? 'secondary' : '' } />
                {/* {index % 2 === 0 ? <tab.Icon /> : <MailIcon />} */}
              </ListItemIcon>
              <ListItemText primary={tab.name} color='secondary' />
              {/* hhhh */}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
      <CssBaseline />
      <PrimaryAppBar handleDrawerToggle={handleDrawerToggle} />

        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            zIndex: (theme) => theme.zIndex.appBar - 1,
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Routes>
        {/* <Route path="/" element={<Login />} /> */}
        <Route path="/" element={<Dashboard />} />
        <Route path="signup" element={<Signup />} />
        <Route exact path="oauth/:platform" element={<DashboardOathLanding />} />
        <Route path="verify/:reference" element={<Dashboard />} />
        <Route path='dashboard'>
          <Route index={true} element={<Dashboard />} />
          <Route path="users" element={<UsersDashbaord />} />
          <Route exact path="profile" element={<Profile />} />
          <Route exact path="platforms" element={<Platforms />} />
          <Route exact path="team" element={<Team />} />
          <Route exact path="payments" element={<Payments />} />
          <Route exact path="settings" element={<Settings />} />
          <Route exact path="requests" element={<RequestsPage />} />
          
        </Route>
      </Routes>
    </Box>
  );
}