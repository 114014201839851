import React, { useEffect } from 'react';
import {Box, Button, Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useSelector, useDispatch } from "react-redux";
import dateAndTime from 'date-and-time';
import { fetchRequests } from '../../store/actions/requestActions';
import RequestsTable from '../../components/RequestsTable';

const Profile = (props) => {
  const state = useSelector((state) => state.requests);
  const team = useSelector((state) => state.users.team);
  const dispatch = useDispatch();

  useEffect(()=> {
    // dispatch(fetchRequests({ team_id: team.team_id, skip: state.skip, limit: state.limit }));
  }, []);

  const nf = new Intl.NumberFormat('en-US');

  const getDim = dim => nf.format(dim);

  const subscriptionNotice = e => {
    if (Number(team.plan_id) === 1 && new Date(team.month_subscription_end_date) < new Date()) {
      return 'Plan will be renewed whenever you send data for analysis'
    }

    if (Number(team.plan_id) > 1 && new Date(team.month_subscription_end_date) < new Date() && new Date(team.subscription_end_date) > new Date()) {
      return 'Plan will be renewed whenever you send data for analysis'
    }

    return ''
  }

  return (
    <Box
        component="main"
        className='page_container'
        sx={{ flexGrow: 1, p: 3, mt: 6 }}
        // sx={{ bgcolor: '#F8F6F4', mb: 5, pb: 5 }}
        >
        <div sx={{ mt: 3 }}>
          <b><h2>Team info</h2></b>
          {/* {JSON.stringify(team)} */}
        </div>

        <Grid container spacing={2} sx={{ fontSize: 16, fontWeight: 800, mt: 2 }}>
          <Grid xs={8}>
          <Box sx={{bgcolor: '#F8F6F4', minWidth:500, p: 3}}>
          <Box>
          <Grid container spacing={2} sx={{ fontSize: 16, fontWeight: 800, mt: 2 }}>
            <Grid xs={5}>
              <Typography sx={{ fontSize: 16 }} gutterBottom>
              <b>Name:</b>
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography sx={{ fontSize: 16, fontWeight: 1000 }}>
              <b>{team.name}</b>
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ fontSize: 16, fontWeight: 800, mt: 2 }}>
            <Grid xs={5}>
              <Typography sx={{ fontSize: 16 }} gutterBottom>
              <b>Plan:</b>
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography sx={{ fontSize: 16, fontWeight: 1000 }}>
              <b>{team.plan_name}</b>
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ fontSize: 16, fontWeight: 800, mt: 2 }}>
            <Grid xs={5}>
              <Typography sx={{ fontSize: 16 }} gutterBottom>
              <b>Monthly request limit:</b>
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography sx={{ fontSize: 16, fontWeight: 1000 }}>
              <b>{team.monthly_request_limit && nf.format(Number(team.monthly_request_limit))}</b>
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ fontSize: 16, fontWeight: 800, mt: 2 }}>
            <Grid xs={5}>
              <Typography sx={{ fontSize: 16 }} gutterBottom>
              <b>Max data dimension:</b>
              </Typography>
              <Typography sx={{fontSize: 8, mt: -1}}>ie. Rows X columns</Typography>
            </Grid>
            <Grid xs={6}>
              <Typography sx={{ fontSize: 16, fontWeight: 1000 }}>
              <b>{team.row_limit && getDim(Number(team.row_limit) * Number(team.column_limit))}</b>
              </Typography>
              
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ fontSize: 16, fontWeight: 800, mt: 2 }}>
            <Grid xs={5}>
              <Typography sx={{ fontSize: 16 }} gutterBottom>
              <b>Current subscription<br />End date:</b>
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography sx={{ fontSize: 16, fontWeight: 1000 }}>
              <b>{team.month_subscription_end_date && dateAndTime.format(new Date(team.month_subscription_end_date), 'ddd, MMM DD YYYY')}</b>
              </Typography>
            </Grid>
          </Grid>


          <Grid container spacing={2} sx={{ fontSize: 16, fontWeight: 800, mt: 2 }}>
            <Grid xs={5}>
              <Typography sx={{ fontSize: 16 }} gutterBottom>
              <b>Total subscription <br />End date:</b>
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography sx={{ fontSize: 16, fontWeight: 1000 }}>
              <b>{team.subscription_end_date && dateAndTime.format(new Date(team.subscription_end_date), 'ddd, MMM DD YYYY')}</b>
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ fontSize: 16, fontWeight: 800, mt: 2 }}>
            <Grid xs={5}>
              <Typography sx={{ fontSize: 16 }} gutterBottom>
              <b>Register date:</b>
              </Typography>
            </Grid>
            <Grid xs={5}>
              <Typography sx={{ fontSize: 16, fontWeight: 1000 }}>
              <b>{team.created_at && dateAndTime.format(new Date(team.created_at), 'ddd, MMM DD YYYY')}</b>
              </Typography>
            </Grid>
          </Grid>

          </Box>

        </Box>
            
          </Grid>

          <Grid xs={4}></Grid>
        </Grid>



        {/* <div>
          { state.requests.length > 0 && <RequestsTable {...state} />}
          { state.requests.length === 0 && (<div>
            <h3>You have no records for data processing for now.</h3>
          </div>)}
            <div>
              { state.page > 1 && <Button variant="text">Prev</Button>}
              
              { state.hasMore && <Button variant="text" onClick={()=>dispatch(fetchRequests({
                team_id: team.team_id,
                skip: state.skip,
                limit: state.limit
                }))}>
                  Next
              </Button>}
              
            </div>
        </div> */}

    </Box>
  )
}

export default Profile;