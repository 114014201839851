import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DateAndTime from 'date-and-time';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(
  name,
  calories,
  fat,
  carbs,
  protein,
) {
  return { name, calories, fat, carbs, protein };
}

const formatDate = d => DateAndTime.format(new Date(d), 'dd, MM DD YY');
const formatDateTime = d => DateAndTime.format(new Date(d), 'MMM DD YYYY HH:mm');

export default function PaymentTable({ records, skip, limit, plansObj }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 850 }} aria-label="customized table">
        <TableHead sx={{backgroundColor: '#ededed'}}>
          <TableRow>
            <StyledTableCell>SN</StyledTableCell>
            {/* <StyledTableCell align="right">Email&nbsp;</StyledTableCell> */}
            <StyledTableCell align="left">Plan&nbsp;</StyledTableCell>
            <StyledTableCell align="left">No months&nbsp;</StyledTableCell>
            <StyledTableCell align="left">Amount&nbsp;<br />(USD)</StyledTableCell>
            <StyledTableCell align="left">Subscription<br />End date&nbsp;</StyledTableCell>
            <StyledTableCell align="left">Payment<br />Date&nbsp;</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((record, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell align="left">{index + 1}</StyledTableCell>
              {/* <StyledTableCell component="th" scope="row">
                {record.transaction_reference}
              </StyledTableCell> */}
              {/* <StyledTableCell align="center">{record.email}</StyledTableCell> */}
              <StyledTableCell align="left">{plansObj[parseInt(record.plan_id)].name}</StyledTableCell>
              <StyledTableCell align="left">{record.number_of_months}</StyledTableCell>
              <StyledTableCell align="left">${record.amount/100}</StyledTableCell>
              <StyledTableCell align="left">{formatDate(record.new_sub_end_date)}</StyledTableCell>
              <StyledTableCell align="left">{formatDateTime(record.created_at)}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}