import React, { useState, useEffect } from 'react';
import { Container, Card, TextField, Button, Box, Alert, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import EvokeLogo from '../../components/media/logos/evoke-logo-no-background.svg'
import logo from '../../components/media/logos/logo192.png';

const ResetPassword = () => {
  const API_ENDPOINT = process.env.REACT_APP_RESULT_API_ENDPOINT;
  const params = useParams();
  const navigate = useNavigate();

  const [payload, setPayload] = useState({
    password: '',
    password2: '',
  });

  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const handleChange = e => {
    setPayload({ ...payload, [e.target.id]: e.target.value });
  }

  const checkAccess = async () => {
    let user = localStorage.getItem('user');
    if (!user) return;
    try {
      user = JSON.parse(user);
      navigate('/');
    } catch (error) {
      
    }
  }


  const verifyTokenApi = async() => {
    const token = encodeURIComponent(params.token)
    try {
      const { data: { email, team_name } } = await axios.get(`${API_ENDPOINT}/auth/signup/verify/${token}`, payload);
      // alert(JSON.stringify(data))
      // setMessage(`Fill the form below to secure your account and join your team <b>${team_name}</b>.`);
      setPayload({ ...payload, email, team_name });
      setLoading(false);
      setShowForm(true);
      setWelcomeMessage(`Fill the form below to update your password`)
    } catch (error) {
      let errMsg = 'You are unable to verify at the moment. Please try again later.';
      if (error?.response?.data) {
        errMsg = error.response.data.message;
      }
      setLoading(false);
      setShowForm(false);
      setError(errMsg);
    }
  }

  useEffect(()=> {
    checkAccess()
    verifyTokenApi()
  },[]);

  const secureApi = async() => {
    const token = encodeURIComponent(params.token)
    try {
      const { data } = await axios.post(`${API_ENDPOINT}/auth/renew-password/${token}`, payload);
      setMessage('Password updated. Login to proceed');
      setPayload({ password: '', password2: '' });
      setError(false);
      setLoading(false);
    } catch (error) {
      let errMsg = 'You are unable to proceed at the moment. Please try again later.';
      if (error?.response?.data) {
        errMsg = error.response.data.message;
      }
      setLoading(false);
      setError(true);
      setMessage(errMsg)
    }
  }

  const validateForm = () => {
    setError(false);
    setMessage('');
    let value

    if (!payload.password || payload.password.length < 8) {
      value = 'Password should be at least 8 characters';
      // return setError(value);
    }
    if (payload.password !== payload.password2) {
      value = 'Passwords do not match';
      // return setError(value);
    }

    if (value) {
      setError(true);
      setMessage(value);
      return
    }

    setLoading(true);
    secureApi();
  }

return (
  <Container>
    <Box sx={{my: 10}}></Box>
    <Grid container spacing={2} sx={{mt: 2}}>
    <Grid xs={6}> </Grid>
    <Grid xs={1}>
      { loading && <CircularProgress /> }
    </Grid>
    <Grid xs={5}> </Grid>

      <Grid xs={3}> </Grid>
      <Grid xs={6}>
      {!loading && <Card sx={{ px: 0, pb: 4 }}>
      <Box><img src={EvokeLogo} height={50} /></Box>
      {/* <Box><img src={logo} height={30} /></Box> */}
      <Box sx={{ py: 3, mx: 3 }}>
      {/* <div style={{fontSize: 15 }}>{welcomeMessage}</div> */}
        <Typography variant='h6' sx={{ mb: 1.5, mt: 1, fontSize: 12, lineHeight: 2 }} color="text.secondary" fontWeight={100}>
          {welcomeMessage}
        </Typography>

      {showForm && <Grid container spacing={2} sx={{mt: 2}}>
        <Grid xs={10}>
        <Typography variant='body1' sx={{ mb: 1.5, mt: 1, fontSize: 12, lineHeight: 2 }} color="text.secondary" fontWeight={600}>
          Welcome {payload.email}
        </Typography>
        <Box sx={{my: 1}}>
          { message && <Alert severity={error ? 'error' : 'success'}>
          {message}
        </Alert>}
      </Box>

        <TextField
          id="password"
          label="Password"
          name="last_name"
          type='password'
          placeholder='********'
          size="small"
          fullWidth
          value={payload.password}
          sx={{my: 1}}
          onChange={handleChange}
        />

        <TextField
          id="password2"
          label="Re-type password"
          name="last_name"
          type='password'
          placeholder='********'
          size="small"
          fullWidth
          sx={{my: 1}}
          value={payload.password2}
          onChange={handleChange}
        />
        </Grid>
        <Grid xs={1}>
        </Grid>

        <Grid xs={4}></Grid>
        <Grid xs={8}>
          <Button variant="contained" onClick={validateForm}>
            { loading && <CircularProgress color='inherit' size={14} />}
              &nbsp; Update password
            </Button>

            <Box sx={{mt: 6}}>
              <Button variant="text" onClick={()=>navigate('/')}>Login</Button>
            </Box>

        </Grid>


      </Grid>}

      </Box>
      {/* <b><h3>Welcome {payload.email} </h3></b> */}


      {/* <Box>_{error} </Box> */}


      </Card>}
      </Grid>
      <Grid xs={3}> </Grid>
    </Grid>
    </Container>
)}

export default ResetPassword;