import * as React from 'react';
import Button from '@mui/material/Button';
import {TextField, CircularProgress, Typography} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SelectComponent from './SelectComponent';

export default function InviteUserDialog(props) {
  const { invite } = props;

  const { open, handleClose } = props;
  const [message, setMessage] = React.useState('');
  // const [loading, setLoading] = React.useState(false);
  const [invitee, setInvitee] = React.useState({
    email: '',
    role: '',
  });

  const roles = [
    { text: '', value: null },
    { text: 'User', value: 'user' },
    { text: 'Owner', value: 'owner' },
  ]

  function handleChange (e) {
    setInvitee({...invitee, [e.target.id]: e.target.value});
  }

  function selectRole (e) {
    setInvitee({...invitee, role: e.target.value});
  }

  const validateForm = () => {
    setMessage('');

    if (!invitee.email) {
      setMessage('Kindly provide and a valid email');
      return
    }

    if (!invitee.role) {
      setMessage('Kindly select a role');
      return
    }

    props.inviteUser({ ...invitee, team_id: props.team_id});
    
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{mt: -3}}><h2><b>Invite user</b></h2></DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontWeight: 600}}>
            Provide email and select a role
          </DialogContentText>
          <Typography sx={{mt: 1, color: 'red'}}>
            {message}
          {invite.message}
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="text"
            value={invitee.email}
            fullWidth
            onChange={handleChange}
          />

        <SelectComponent
          data={invitee.role}
          targetId={'role'}
          options={roles}
          handleChange={selectRole}
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose(!open)}>Cancel</Button>
          <Button variant="contained" onClick={validateForm}>
            { invite.loading && <CircularProgress color='inherit' size={14} />}
            &nbsp;Invite User
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
