import React, { useState, useEffect } from 'react';
import { Container, Card, CardContent, Typography, Button, CardActions, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useSelector, useDispatch } from "react-redux";
import PlansCard from './PlansCard';
import { updateValue } from '../../store/reducers/settingsReducers';
import { getPlans } from '../../store/actions/settingsActions';
import { v4 as uuidv4 } from 'uuid';
// import SquadPay from "react-squadpay";
import SelectComponent from '../dialogs/SelectComponent';
import { usePaystackPayment } from 'react-paystack';
import './settingStyles.css';
import axios from 'axios';
import { getHeaders } from '../../store/actions/utils';
const API_ENDPOINT = process.env.REACT_APP_RESULT_API_ENDPOINT;

const PaymentSettings = () => {

  let first2Months = [
    { text: 1, value: 1, discountRate: 0 },
    { text: 2, value: 2, discountRate: 0 },
]
const month3to12 = [
  { text: 3, value: 3, discountRate: 0 },
  { text: 4, value: 4, discountRate: 0 },
  { text: 5, value: 5, discountRate: 0 },
  { text: 6, value: 6, discountRate: 5 },
  { text: 7, value: 7, discountRate: 5 },
  { text: 8, value: 8, discountRate: 5 },
  { text: 9, value: 9, discountRate: 8 },
  { text: 10, value: 10, discountRate: 8 },
  { text: 11, value: 11, discountRate: 8 },
  { text: 12, value: 12, discountRate: 15 },
]


  const state = useSelector((state) => state.settings.payment);
  const userObj = useSelector((state) => state.users);
  // const team = useSelector((state) => state.users.team);

  const { user, team } = userObj;
  const dispatch = useDispatch();

  useEffect(()=> {
    dispatch(updateValue({feature: 'payment', key: 'loading', value: true }));
    dispatch(getPlans({team_id: team.team_id}));

  }, []);


  const [selectPlan, setSelectPlan] = useState({});
  const [months, setMonths] = useState(1);
  const [view, setView] = useState('plans');
  const [updatingAccount, setUpdatingAccount] = useState('');
  const [message, setMessage] = useState('');
  const [monthList, setMonthList] = useState([...first2Months, ...month3to12]);
  const key = process.env.REACT_APP_SQUAD_PAY_PUBLIC_KEY;
  // const key = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
  const email = process.env.NODE_ENV === 'development' ? 'kay4040@mailinator.com' : user.email

  const [ params, setParams ] = useState({
    key,
    currencyCode: "USD",
    payment_channels: ['card'],
    pass_charge: true,
    email,
    amount: 10,
    metadata: {
      team_id: team.team_id,
      plan_id: selectPlan.id,
      email,
      months,
      number_of_months: months
    }
  });

  // const monthList = [
  //   // { text: 1, value: 1, discountRate: 0 },
  //   // { text: 2, value: 2, discountRate: 0 },

  // ];

  const config = {
    reference: uuidv4(),
    email,
    amount: Number(params.amount) * 100,
    currency: 'USD',
    channels: ['card'],
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    metadata: {
      team_id: Number(team.team_id),
      plan_id: Number(selectPlan.id),
      email,
      months,
      number_of_months: Number(months)
    }
};

  const handleChange = e => {
    const value = Number(e.target.value) || 1;
    calculateTotal('months', value);
    setMonths(value);
  }

  const preBackToPlans = () => {
    setMonths(1);
    setView('plans')
  }

  const pickPlan = plan => {
    let mo = 1;
    // if (Number(plan.id) === 2) {
    //   setMonthList(month3to12);
    //   mo = 3;
    //   setMonths(3);
    //   setParams({ ...params, months: 3 })
    // } else {
    //   setMonthList([...first2Months, ...month3to12]);
    //   setMonths(1)
    //   setParams({ ...params, months: 1 })
    // }

    setSelectPlan(plan);
    calculateTotal('plan', plan, mo);
    setView('checkout');
  }

  const calculateTotal = (key, value, mo) => {
    let m = mo || months; let p = selectPlan;
    if (key === 'months') m = value;
    if (key === 'plan') p = value;
    const total = m * Number(p.price)
    let discount = Number(total * (monthList[m - 1].discountRate / 100));
    if (discount > 0) discount = Math.round(discount);
    let amount = total - discount;
    // amount = amount.toFixed(2);
    amount = Math.round(amount)
    
    setParams({ ...params, amount, metadata: { ...params.metadata, months: m, discount, plan_id: p.id } });
    // Remove amount = 1
    config.metadata = { ...params.metadata, months: m, discount, plan_id: p.id };
  }

  const Close = () => {
    console.log("Widget closed")
  }
  
  const Load = () => {
    console.log("Widget Loaded")
  }
  
  /**
   * @param {object} data
   * @description  reponse when payment is successful
   */
  const Success = (data) => {
  }

const verifyPayment = async payload => {
  const headers = getHeaders(team.team_id);
  try {
    const { data } = await axios.post(`${API_ENDPOINT}/settings/payment/verify`, payload, headers);
    window.location.reload();
  } catch (error) {
    console.log('-error: ', error)
  }
}

const onPaystackSuccess = (response) => {
  setMessage('Payment successful! We are updating your account at the moment')
  setUpdatingAccount('updating');
  verifyPayment({provider: 'paystack', ...response, mode: 'callback' });
};

const onPaystackClose = (e) => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
  if (process.env.NODE_ENV === 'development') {
    console.log('onPaystackClose', e);
  }
}

const PaystackHook = () => {
  // config.metadata = params.metadata;
  // console.log('PaystackHook: ', config);
  const initializePayment = usePaystackPayment(config);
  return (
    <div>
      <button className={'Button4'} onClick={() => {
        initializePayment(onPaystackSuccess, onPaystackClose)
      }}>Pay ${params.amount} now</button>
    </div>
  );
};

return (
  <Container>
    { view === 'plans' &&
    <section>
      <Grid container spacing={2} columns={{ xs: 4, sm: 6, md: 12 }}>
        { state.plans.map(plan => parseInt(plan.id) &&
          (<Grid key={plan.id} xs={12} md={4} sm={6}>
            <PlansCard pickPlan={pickPlan} plan={plan} user={user} currentPlan={plan.id === team.plan_id} team_plan_id={team.plan_id} />
          </Grid>)
          )}
      </Grid>
    </section>
    }

      { view !== 'plans' &&
        <Box sx={{maxWidth: 500}}>
        <Box>
          <Grid container spacing={2}>
          <Grid xs={4}>
            <Typography sx={{ fontSize: 16 }} gutterBottom>
              <b> Plan</b>
            </Typography>
          </Grid>
          <Grid xs={4}>
            <Typography sx={{ fontSize: 16 }} gutterBottom>
              <b>{selectPlan.name} plan</b>
            </Typography>
          </Grid>
          <Grid xs={4}>
          </Grid>
        </Grid>

          <Grid container spacing={2} sx={{ fontSize: 16, fontWeight: 800, mt: 3 }}>
            <Grid xs={4}>
              <Typography sx={{ fontSize: 16 }} gutterBottom>
              <b>Months:</b>
              </Typography>
            </Grid>
            <Grid xs={4}>
              <Typography sx={{ fontSize: 16, fontWeight: 1000 }}>
              <b>{months}</b>
              </Typography>
            </Grid>
            <Grid xs={4}>
              <SelectComponent
                options={monthList}
                handleChange={handleChange}
                targetId={'months'}
                data={months}
                sx={{ mt: 3 }}
              />
            </Grid>
          </Grid>

            <Grid container spacing={2}>
              <Grid xs={4}>
                <Typography sx={{ fontSize: 16 }} gutterBottom>
                  <b>Total amount</b>
              </Typography>
              </Grid>
              <Grid xs={4}>
                <Typography sx={{ fontSize: 20 }} gutterBottom>
                <b>USD {params.amount} </b>
                </Typography>
              </Grid>
              <Grid xs={4}>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{my: 3}}>
            <Grid xs={4}>
            <Typography sx={{ fontSize: 12, mt: 0, color: 'text.secondary' }} gutterBottom>
                Save $ { params.metadata.discount }
              </Typography>

            </Grid>
            <Grid xs={4}>
            <PaystackHook />
              {/* <SquadPay
                className={'Button4'}
                params={params}
                onClose={Close}
                onLoad={Load}
                onSuccess={(res)=>Success(res)}
                sx={{mt: 2}}
              >
                Pay ${params.amount} with Squad now
              </SquadPay> */}
            </Grid>
            <Grid xs={4}>
            </Grid>
          </Grid>

        </Box>
        <Box>
          <Typography sx={{ mb: 1.25, mt:8 }}>
          { updatingAccount === 'updating' && 'Payment successful! We are updating your account at the moment' }
          { updatingAccount === 'updated' && 'Account successful updated. Click on the button to proceed' }
          </Typography>
        </Box>
        <CardActions sx={{mt: 3}}>
          <Button onClick={()=>preBackToPlans('plans')}>Back to plans</Button>
        </CardActions>
      </Box>}

  </Container>
)}

export default PaymentSettings;