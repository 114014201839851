import './App.css';
import { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Signup from "./pages/Sites/Signup";
import Landing from './pages/Sites/Landing';
import AuthenticatedApp from './AuthenticatedApp';
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { getMe } from './store/actions/userActions';
import CreateTeam from './pages/Sites/CreateTeam';
import AcceptInvite from './pages/Sites/AcceptInvite';
import ResetPassword from './pages/Sites/ResetPassword';
import PageNotFound from './pages/Sites/PageNotFound';
import OauthLanding from './pages/Sites/oauth/Landing';
// import Login from './pages/Sites/login';
// import Landing from './pages/Sites/Landing';
// import Loading from './Loading';

import { ThemeProvider, createTheme, responsiveFontSizes  } from '@mui/material/styles';
let theme = createTheme({
  typography: {
    fontFamily: [
      'Work Sans',
      'Source Sans Pro',
      'sans-serif',
      // '-apple-system',
      // 'BlinkMacSystemFont',
      // '"Segoe UI"',
      // 'Roboto',
      // '"Helvetica Neue"',
      // 'Arial',
      // 'sans-serif',
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ].join(','),
    lineHeight: 25,
    fontSize: 15,
  },
});
theme = responsiveFontSizes(theme);

function App() {
  const [view, setView] = useState('loading');
  const dispatch = useDispatch();
  const { search } = useLocation();
  let [searchParams, setSearchParams] = useSearchParams(search);
  const code = searchParams.get('code');
  const codeState = searchParams.get('state');
  const access = searchParams.get('access');

  const state = useSelector((state) => state.users);
  // console.log(':_: ', process.env.NODE_ENV);

  const checkAccess = async () => {
    if (code && codeState=== 'install' && !access) {
      await localStorage.removeItem('user');
    }

    let user = localStorage.getItem('user');
    if (!user) {
      setView('login');
    }

    try {
      user = JSON.parse(user);
      if (!user || !user.token) {
        localStorage.removeItem('user');
        return setView('login');
      }
      await dispatch(getMe());
      setView('dashboard');
    } catch (error) {
      
    }
  }

  useEffect(()=> {
    checkAccess()
  }, []);

  const GuestRoutes = () => (
    <Routes>
    <Route path="/" element={<Landing />} />
    <Route path="signup" element={<Signup />} />
    <Route path="create-team" element={<CreateTeam />} />
    <Route path="/verify/:token" element={<CreateTeam />} />
    <Route path="/accept/:token" element={<AcceptInvite />} />
    <Route path="/reset-password/:token" element={<ResetPassword />} />
    <Route path="/oauth/:platform" element={<OauthLanding />} />
    
    <Route path="*" element={<PageNotFound />} />
    {/* <Route path='' /> */}
  </Routes>
  );

  return (
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      { (view === 'loading' || state.loading) && <LinearProgress color='inherit' size={4} />}
      { (view === 'login' && (!state.user || !state.loading)) && <GuestRoutes /> }
      { state.user && <AuthenticatedApp />}
      </GoogleOAuthProvider>
    </ThemeProvider>  
  )
}

export default App;
