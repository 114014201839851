import React, { useState, useCallback } from 'react';
import { Container, Button, Card, Divider, Box, Typography, Alert, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SlackLogin from 'react-slack-login';
import DiscordSVG from '../../components/media/icons/discord.svg';
import axios from 'axios';
import { purple } from '@mui/material/colors';
import { useNavigate, useLocation } from "react-router-dom";
import { SlackLoginButton } from "react-social-login-buttons";

// import evokeLogo from './media/logos/evoke-logo-no-background.svg';
import evokeLogo2 from '../../components/media/logos/evoke-logo-no-background.svg';
import { useDiscordLogin, UseDiscordLoginParams } from 'react-discord-login';
// import SendIcon from '@mui/icons-material/Send';
// import GoogleIcon from '@mui/icons-material/Google';

import Login from './login';
import Signup from './Signup';
import ResetPassword from './ForgotPassword';
import { useSelector } from "react-redux";
/*
Removed Google login
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import GoogleButton from 'react-google-button';
*/
import {
  GoogleLoginButton,
} from 'react-social-login-buttons';

import {
  LoginSocialGoogle,
} from 'reactjs-social-login'


// style="align-items:center;color:#fff;background-color:#4A154B;border:0;border-radius:4px;display:inline-flex;font-family:Lato, sans-serif;font-size:16px;font-weight:600;height:48px;justify-content:center;text-decoration:none;width:256px"
// style="height:20px;width:20px;margin-right:12px"
const SlackStyle2 = { height: '20px', width: '20px', marginRight: '12px' };
const SlackStyle = { alignItems: 'center', color: '#fff', backgroundColor: '#4A154B', borderRadius: '4px', display: 'inline-flex', fontFamily: 'Lato, sans-serif', fontSize: '16px', fontWeight: 600, height: '48px', justifyContent: 'center', textDecoration: 'none', width: '256px' }
// const cc = ()=><Box style={{ alignItems: 'center', color: '#fff', backgroundColor: '#4A154B', borderRadius: '4px', display: 'inline-flex', fontFamily: 'Lato, sans-serif', fontSize: '16px', fontWeight: 600, height: '48px', justifyContent: 'center', textDecoration: 'none', width: '256px' }}></Box>
let slackLoginLink = `https://slack.com/openid/connect/authorize?`
const resp_scopes = 'response_type=code&scope=openid%20profile%20email&state=login';
const client_id = `client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}`;
const redirect_uri = `redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URL}`;
slackLoginLink+=`${resp_scopes}&${client_id}&${redirect_uri}`;

const SlackAppLoginButton = ()=><Box>
  <div><a href={slackLoginLink} style={SlackStyle} ><svg xmlns="http://www.w3.org/2000/svg" style={SlackStyle2} viewBox="0 0 122.8 122.8"><path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path><path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path><path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path><path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path></svg>Sign in with Slack</a></div></Box>

const Landing = () => {
  const navigate = useNavigate();
  const [view, setView] = useState('login');
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  
  // const dispatch = useDispatch();
  const state = useSelector((state) => state.users);


  const onLoginStart = useCallback(() => {
    console.log('login starting....', process.env.REACT_APP_GOOGLE_CLIENT_ID)
  }, []);
  
  const socialLoginResolve = async (resource) => {
    const { provider, credential } = resource;
    if (!provider || !credential) return;
  
    let url = `${process.env.REACT_APP_RESULT_API_ENDPOINT}/auth/social`;
    if (provider === 'google') {
      if (!credential) return;
    }
  
    try {
      const { data } = await axios.post(url, resource);
      if (data.user) {
        localStorage.setItem("user", JSON.stringify(data.user));
        window.location.reload()
        if (data.newSignup) {
          // create team
        }
      }     
    } catch (error) {
      // console.log('-----error ', error);
      setMessage(error.response.message);
    }
  }

  // const onSuccess = useGoogleLogin({
  //   onSuccess: tokenResponse => console.log('useGoogleLogin::: ', tokenResponse),
  //   flow: 'auth-code',
  // });

  // const discordOauthUrl = process.env.REACT_APP_DISCORD_OAUTH_URL;
  // scope=email+guilds.join+bot+identify+guilds+gdm.join+connections+guilds.members.read+dm_channels.read

  const discordLoginParams = {
    clientId: process.env.REACT_APP_DISCORD_CLIENT_ID,
    redirectUri: process.env.REACT_APP_DISCORD_REDIRECT_URL,
    responseType: 'token', // or 'code'
    scopes: ['identify', 'email', 'guilds', 'connections', 'guilds.members.read'],
    onSuccess: response => {
        // Handle successful login
        // console.log('Discord Login successful:', response);
    },
    onFailure: error => {
        // Handle login failure
        // console.error('Login failed:', error);
    },
  };

  const verifySlackLogin = async (code, view = 'preview') => {
    setLoading(true);
    setError(false);
    const user = localStorage.getItem('user');
    // if (!user) return navigate('/');
    if (user) {
      navigate(0);
    }

    const url = process.env.REACT_APP_RESULT_API_ENDPOINT + '/auth/social/slack';
    const redirect_uri = process.env.REACT_APP_SLACK_REDIRECT_URL;
    const payload = {code, provider: 'slack', view, redirect_uri };
    try {
      const { data } = await axios.post(url, payload);
      if (data.login) {
        localStorage.setItem('user', JSON.stringify(data.user));
        setMessage('Login successful');
        navigate(0);
        // window.location.reload();
      }

      setLoading(false);
    } catch (error) {
      setError(true)
      setMessage(error.response.message || 'Error verifying login')
      setLoading(false);
    }
  }


  const onFailed = (e) => {
    // console.log('e_d0 fail', e)
  }

  const onSuccess = (code) => {
    // alert('e don succeed', e)
    // setMessage('Verifying login ....')
    setLoading(true)
    verifySlackLogin(code)
  }

  
  const { buildUrl, isLoading: discordIsLoading } = useDiscordLogin(discordLoginParams);

  return (
    <Box sx={{backgroundColor: 'GrayText', minHeight: 820 }}>
      <Container>
        <Box sx={{minHeight: 100 }}></Box>
        {!state.loading && <Grid container spacing={2} xs={{ mt: 3 }}>
          <Grid xs={12} md={3}>
          </Grid>
          <Grid xs={12} md={5}>
            <Card sx={{ px: 1, pb: 4 }}>
            <Box sx={{my: 1}}><img src={evokeLogo2} height={35} /></Box>
            {view !== 'resetPassword' && <Container sx={{mb: 2, mt: 3}} align="center">
              <Typography variant='h4' sx={{ mb: 1.5, mt: 1, fontSize: 12, lineHeight: 2 }} color="text.secondary" fontWeight={500}>
                Welcome back
              </Typography>

              <Grid container spacing={2}>
                <Grid xs={2} md={1} style={{marginTop: -10}}></Grid>
                <Grid xs={8} md={9}>
                {/* {slackLoginLink} */}
                  <Box>
                    <Button variant="contained" sx={{backgroundColor: purple[700]}} onClick={() => (window.location.href = buildUrl())} disabled={discordIsLoading}>
                      <img src={DiscordSVG} sx={{mr: 2}} height={40} alt='discord-logo' /><Box sx={{ml: 2, fontWeight: 600}}>
                        Login with Discord
                        </Box></Button>
                  </Box>

                  <Box>

                  </Box>
                  {/* <Box sx={{mt:4}}>
                  <GoogleLogin
                    onSuccess={data => {
                      const { credential } = data; 
                      socialLoginResolve({ provider: 'google', credential })
                    }}
                    onError={() => {
                      console.log('credentialResponse: Login Failed');
                    }}
                    useOneTap
                  />
                  </Box> */}
                </Grid>
              </Grid>

              {/* slackUserScope='identity.basic,identity.email,identity.team' */}
              {/* slackUserScope='identity,users:read,users:read.email' */}

              <Box sx={{my: 4}}>
                <Box sx={{ml: -3, mt: 8}}><SlackAppLoginButton /></Box>
                
                {/* <SlackLogin
                  redirectUrl={process.env.REACT_APP_SLACK_REDIRECT_URL}
                  onFailure={onFailed}
                  onSuccess={onSuccess}
                  slackClientId={process.env.REACT_APP_SLACK_CLIENT_ID}
                  // slackUserScope=',identity.team'
                  slackUserScope='identity.basic,identity.email,identity.team'
                /> */}
              </Box>

              <Box>
                { message && <Alert severity={error ? 'error' : 'success'}>
                  <Typography variant='h5' sx={{ mb: 1.5, mt: 1, fontSize: 12, lineHeight: 2 }} color="text.secondary" fontWeight={100}>
                  {message}
                  </Typography>
                </Alert>}
              </Box>

              {/* <Divider sx={{mt: 3}}>Or</Divider> */}
              </Container>
              }

            <Box>{message}</Box>
            <Box align="center">
              { loading && <CircularProgress color='inherit' size={19} />}
            </Box>
            <Box sx={{mt: 2}}>
              {/* { view === 'login' && <Login setView={setView} />} */}
              {/* { view === 'signup' && <Signup setView={setView} />} */}
              {/* { view === 'resetPassword' && <ResetPassword setView={setView} /> } */}
            </Box>

            </Card>
          </Grid>
          <Grid xs={1}>
          </Grid>
        </Grid>}

      </Container>

    </Box>
  )
}

export default Landing;